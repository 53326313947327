import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
// import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// import Typography from '../../modules/components/Typography';
// import TextField from '@mui/material/TextField';
// import TextField from '../../modules/components/TextField';
import NcuaIcon from '../../assets/ncua.png';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.0)',
  // ...theme.typography.body2,
  ...theme.typography.body1,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  boxShadow: 'none', // toggle for development
  borderRadius: 0,
}));

// function Copyright() {
//     return (
//         <React.Fragment>
//             {'© '}
//             <Link color="inherit" href="https://mui.com/">
//                 Hilandcu.com
//             </Link>{' '}
//             {new Date().getFullYear()}
//         </React.Fragment>
//     );
// }

const BasicLayoutRoot = styled('section')(({ theme }) => ({
  color: theme.palette.common.white,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  // [theme.breakpoints.up('xs')]: {
  //     height: '80vh',
  //     minHeight: 400,
  //     maxHeight: 1300,
  //     maxHeight: 450,
  // },
  // [theme.breakpoints.up('sm')]: {
  //     height: '80vh',
  //     minHeight: 400,
  //     maxHeight: 1300,
  //     maxHeight: 500,
  // },
}));

// const iconStyle = {
//     width: 48,
//     height: 48,
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     backgroundColor: 'warning.main',
//     mr: 1,
//     '&:hover': {
//         bgcolor: 'warning.dark',
//     },
// };

// const LANGUAGES = [
//     {
//         code: 'en-US',
//         name: 'English',
//     },
//     {
//         code: 'fr-FR',
//         name: 'Français',
//     },
// ];

export default function AppFooter() {
  const cardHeight = 150;
  return (
    <Box
      component="footer"
      sx={{
        display: 'flex',
        bgcolor: 'rgb(246, 246, 246, 1.0)',
        marginTop: 6,
      }}
    >
      <Box sx={{ flexGrow: 1, marginY: { xs: 1, sm: -1, md: 2 } }}>
        <BasicLayoutRoot
          style={{
            // backgroundImage: `url(${SplashImage})`,
            // backgroundColor: '#737373', // Average color of the background image.
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover', // contain or cover
            backgroundPosition: 'center',
          }}
        >
          {/* <Container
                        sx={{
                            mt: 3,
                            mb: 14,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    > */}
          {/* Increase the network loading priority of the background image. */}

          <Grid
            container
            spacing={{ xs: 4, sm: 1, md: 1 }}
            columns={{ xs: 4, sm: 8, md: 14 }}
            sx={{
              padding: {
                xs: 4,
                sm: 6,
                md: 3,
              },
            }}

            // sx={{ marginTop: '7%' }}
          >
            <Grid item xs={4} sm={4} md={3}>
              <Box
                sx={{
                  // width: 315,
                  height: cardHeight,
                  // backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  borderRadius: '5%',
                  // boxShadow:
                  //     '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
                }}
              >
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box>
                    <Item
                      sx={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        fontSize: {
                          xs: 20,
                          sm: 22,
                          md: 16,
                          lg: 18,
                        },
                      }}
                    >
                      Office
                    </Item>
                    <Item
                      sx={{
                        textAlign: 'center',
                        fontSize: {
                          xs: 13,
                          sm: 13,
                          md: 12,
                          lg: 12,
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 'bold',
                        }}
                      >
                        Hi-Land Credit Union
                      </Typography>
                      <Typography variant="body2">
                        5366 S Executive Park Dr
                      </Typography>
                      <Typography variant="body2">
                        Murray, Utah 84117
                      </Typography>
                    </Item>
                    <Item>
                      {/* <Button
                                        variant="outlined"
                                        size="sm"
                                        sx={{
                                            // marginTop: 2,
                                            fontSize: 10,
                                            borderRadius: 5,
                                            // backgroundColor:
                                            //     'rgb(255, 200, 87, 0.85)',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                // backgroundColor:
                                                //     'rgb(255, 200, 87, 1.0)',
                                                // boxShadow:
                                                //     '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
                                            },
                                        }}
                                    >
                                        Get Started
                                    </Button> */}
                    </Item>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4} sm={4} md={3}>
              <Box
                sx={{
                  // width: 315,
                  height: cardHeight,
                  // backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  borderRadius: '5%',
                  // boxShadow:
                  //     '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
                }}
              >
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box>
                    <Item
                      sx={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        fontSize: {
                          xs: 20,
                          sm: 22,
                          md: 16,
                          lg: 18,
                        },
                      }}
                    >
                      Help
                    </Item>
                    <Item
                      sx={{
                        textAlign: 'center',
                        fontSize: {
                          xs: 13,
                          sm: 13,
                          md: 12,
                          lg: 12,
                        },
                      }}
                    >
                      <Box>
                        <Link
                          variant="body2"
                          underline="always"
                          href="/contact"
                        >
                          Contact Us
                        </Link>
                      </Box>
                      <Box>
                        <Link
                          variant="body2"
                          underline="always"
                          href="/contact"
                        >
                          Consultation
                        </Link>
                      </Box>
                      <Box>
                        <Link
                          variant="body2"
                          underline="always"
                          href="/services#olb"
                        >
                          Online Banking
                        </Link>
                      </Box>
                      <Box>
                        <Link
                          variant="body2"
                          underline="always"
                          href="/resources"
                        >
                          Resources
                        </Link>
                      </Box>
                    </Item>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4} sm={4} md={3}>
              <Box
                sx={{
                  // width: 315,
                  height: cardHeight,
                  // backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  borderRadius: '5%',
                  // boxShadow:
                  //     '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
                }}
              >
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // marginRight: '7%',
                  }}
                >
                  {/* <Box
                                        component="img"
                                        src={CarIcon}
                                        alt="increase priority"
                                        sx={{
                                            width: {
                                                xs: 80,
                                                sm: 65,
                                                md: 70,
                                                lg: 80,
                                            },
                                        }}
                                    /> */}
                  <Box>
                    <Item
                      sx={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        fontSize: {
                          xs: 20,
                          sm: 22,
                          md: 16,
                          lg: 18,
                        },
                      }}
                    >
                      Legal
                    </Item>
                    <Item
                      sx={{
                        textAlign: 'center',
                        fontSize: {
                          xs: 13,
                          sm: 13,
                          md: 12,
                          lg: 12,
                        },
                      }}
                    >
                      <Box>
                        <Link
                          variant="body2"
                          underline="always"
                          href="/security"
                        >
                          Report Fraud
                        </Link>
                      </Box>
                      <Box>
                        <Link variant="body2" underline="always" href="/legal">
                          Privacy Policy
                        </Link>
                      </Box>
                      <Box>
                        <Link variant="body2" underline="always" href="/legal">
                          Terms of Use
                        </Link>
                      </Box>
                      <Box>
                        <Link variant="body2" underline="always" href="/legal">
                          Notice of Data Collection
                        </Link>
                      </Box>
                    </Item>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4} sm={4} md={5} alignItems="center">
              <Box
                sx={{
                  // width: 315,
                  height: cardHeight,
                  // backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  borderRadius: '5%',
                  // boxShadow:
                  //     '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
                }}
              >
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    component="img"
                    src={NcuaIcon}
                    alt="increase priority"
                    sx={{
                      width: {
                        xs: 300,
                        sm: 240,
                        md: 295,
                        lg: 375,
                      },
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
          {/* <Typography
                color="inherit"
                align="center"
                variant="h2"
                marked="center"
            >
                Upgrade your Sundays
            </Typography>
            <Typography
                color="inherit"
                align="center"
                variant="h5"
                sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
            >
                Enjoy secret offers up to -70% off the best luxury hotels every
                Sunday.
            </Typography>
            <Button
                color="secondary"
                variant="contained"
                size="large"
                component="a"
                href="/premium-themes/onepirate/sign-up/"
                sx={{ minWidth: 200 }}
            >
                Register
            </Button>
            <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
                Discover the experience
            </Typography> */}
          {/* </Container> */}
        </BasicLayoutRoot>
      </Box>
    </Box>
  );
}
