import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  borderRadius: 3,
  //   border: '0px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ href, title }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        sx={{
          textTransform: 'none',
          font: 'inherit',
          color: 'rgb(8, 8, 238)',
          textDecoration: 'underline',
          paddingX: 0,
          paddingTop: 0,
          paddingBottom: 0.1,
          '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
          },
        }}
        onClick={handleOpen}
      >
        <b>{title}</b>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="external-link"
        aria-describedby="external-link-description"
      >
        <Box sx={style}>
          <IconButton onClick={handleClose} disableRipple>
            <ArrowBackIcon fontSize="small" />
          </IconButton>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            textAlign="center"
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Third Party Site Disclaimer
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, fontSize: '0.8rem' }}
            >
              Clicking this link directs you to a website not operated by
              Hi-Land Credit Union. We're not liable for its availability,
              content, or any transactions with third parties there. Please
              proceed with caution.
            </Typography>

            <Button
              href={href}
              onClick={handleClose}
              target="_blank"
              rel="noopener noreferrer"
              variant="contained"
              sx={{ backgroundColor: 'rgb(40, 40, 42, 0.9)', mt: 3 }}
            >
              Proceed to website
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
