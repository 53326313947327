import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import MainAppBar from '../components/nav/MainAppBar';
import AppFooter from '../components/nav/AppFooter';
import SignIn from '../components/login/SignIn';

export default function SignInPage() {
    return (
        <Container disableGutters>
            <MainAppBar />

            <Box
                display="flex"
                justifyContent="center"
                alignContent="center"
                sx={{
                    paddingX: { xs: 3, sm: 9, md: 25, lg: 25 },
                    paddingTop: { xs: 6, sm: 9, md: 9, lg: 9 },
                    paddingBottom: { xs: 30 },
                }}
            >
                <SignIn />
            </Box>
            <AppFooter />
        </Container>
    );
}
