import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Input formatting
import { Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

const vehicleTypeOptions = [
  {
    value: 'auto',
    label: 'Auto',
  },
  {
    value: 'utv',
    label: 'UTV',
  },
  {
    value: 'rv',
    label: 'RV',
  },
];

const vehicleConditionOptions = [
  {
    value: 'new',
    label: 'New',
  },
  {
    value: 'used',
    label: 'Used',
  },
];

export default function AutoLoanForm({ control }) {
  return (
    <Box>
      <Grid
        container
        spacing={{ xs: 1, sm: 1.5, md: 2 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid xs={4} sm={8} md={12}>
          <Typography fontSize={{ xs: 16, sm: 20 }}>
            Loan Information
          </Typography>
        </Grid>
        <Grid item xs={4} sm={8} md={12}>
          <Controller
            name="auto.amount"
            control={control}
            defaultValue=""
            render={({
              field: { ref, onChange, name, value },
              fieldState: { invalid, error },
            }) => (
              <NumericFormat
                inputRef={ref}
                name={name}
                value={value}
                onChange={onChange}
                error={invalid}
                helperText={error?.message}
                label="Amount"
                fullWidth
                thousandSeparator
                prefix="$"
                customInput={TextField}
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={8} md={12}>
          <Controller
            name="auto.vehicle.type"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                error={invalid}
                helperText={error?.message}
                select
                fullWidth
                label="Vehicle Type"
              >
                {vehicleTypeOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    id={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={4} sm={8} md={12}>
          <Typography fontSize={{ xs: 16, sm: 20 }}>Vehicle Details</Typography>
        </Grid>
        <Grid item xs={4} sm={4} md={6}>
          <Controller
            name="auto.vehicle.condition"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                error={invalid}
                helperText={error?.message}
                select
                fullWidth
                label="Vehicle Condition"
              >
                {vehicleConditionOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    id={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={6}>
          <Controller
            name="auto.vehicle.mileage"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                error={invalid}
                helperText={error?.message}
                fullWidth
                label="Estimated Mileage"
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            name="auto.vehicle.make"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                error={invalid}
                helperText={error?.message}
                fullWidth
                label="Vehicle Make"
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            name="auto.vehicle.model"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                error={invalid}
                helperText={error?.message}
                fullWidth
                label="Vehicle Model"
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={8} md={4}>
          <Controller
            name="auto.vehicle.year"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                error={invalid}
                helperText={error?.message}
                fullWidth
                label="Vehicle Year"
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
