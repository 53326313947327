import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Alert from '@mui/material/Alert';

import { Controller } from 'react-hook-form';

import AutoIcon from '../../../assets/icons/banking/car.png';
import CardIcon from '../../../assets/icons/banking/card.png';
// import HomeIcon from '../../../assets/icons/banking/home.png';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(3),
        border: 1,
        '&.Mui-disabled': {
            border: 1,
        },
        '&:not(:first-of-type)': {
            // borderRadius: theme.shape.borderRadius,
            borderRadius: 6,
        },
        '&:first-of-type': {
            // borderRadius: theme.shape.borderRadius,
            borderRadius: 6,
        },
    },
}));

const StyledToggleButton = styled(ToggleButton)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '&.MuiToggleButton-root': {
        backgroundColor: 'rgb(249, 252, 254, 1)',
        outlineColor: 'rgb(207, 214, 224, 1)',
        outlineWidth: '1.5px',
        outlineStyle: 'solid',
    },
    '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: 'rgb(249, 252, 254, 1)',
        outlineColor: 'rgb(47, 105, 196, 1)',
        outlineWidth: '3px',
        outlineStyle: 'solid',
    },
});

export default function ApplicationType({
    control,
    setValue,
    watchLoanType,
    reset,
    errors,
}) {
    const handleLoanType = (event, loanType) => {
        if (loanType === null) {
            return;
        }
        if (watchLoanType !== '') {
            reset(); // reset form if loan type is changed
        }
        setValue('application.type', loanType);
    };

    return (
        <Box>
            {errors.application?.type?.message ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Alert variant="standard" severity="error">
                        Please make a selection
                    </Alert>
                </Box>
            ) : null}
            <Controller
                render={({ field }) => (
                    <StyledToggleButtonGroup
                        {...field}
                        size="large"
                        exclusive
                        onChange={handleLoanType}
                        aria-label="loan type"
                    >
                        <StyledToggleButton
                            value="auto"
                            aria-label="auto loan"
                            sx={{
                                height: 150,
                                width: 150,
                            }}
                        >
                            <img src={AutoIcon} width={40} height={40} alt="" />
                            <Typography>Auto</Typography>
                        </StyledToggleButton>
                        <StyledToggleButton
                            value="card"
                            aria-label="credit card"
                            sx={{
                                height: 150,
                                width: 150,
                            }}
                        >
                            <img src={CardIcon} width={40} height={40} alt="" />
                            <Typography>Credit Card</Typography>
                        </StyledToggleButton>
                        {/* <StyledToggleButton
                            value="home"
                            aria-label="home loan"
                            sx={{
                                height: 100,
                                width: 100,
                            }}
                        >
                            <img src={HomeIcon} width={40} height={40} alt="" />
                            <Typography>Home</Typography>
                        </StyledToggleButton> */}
                    </StyledToggleButtonGroup>
                )}
                name="application.type"
                control={control}
            />
        </Box>
    );
}
