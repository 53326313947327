import React from 'react';
import Box from '@mui/material/Box';
import CopywriteLayout from '../components/layouts/CopywriteLayout';

import DollarIcon from '../assets/icons/banking/dollar.png';
import MagnifierIcon from '../assets/icons/banking/magnifier.png';
import PlantIcon from '../assets/icons/banking/plant.png';

import ExternalLink from '../components/modals/ExternalLink';

const SectionTitle = ({ icon, children }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    }}
  >
    <Box
      style={{
        display: 'flex',
        marginRight: '10px',
      }}
    >
      <img src={icon} alt="" width={40} height={40} />
    </Box>
    {children}
  </div>
);

export default function EducationCenterPage() {
  return (
    <CopywriteLayout>
      <br />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h1>Hi-Land Credit Union Education Center</h1>
      </div>
      <br />
      <body>
        Lifelong learning is essential, and comprehending finances can be
        particularly challenging in a dynamic economy. At Hi-Land Credit Union,
        recognizing this, we've established our Education Center. Within these
        pages, you'll find timely articles and advice covering diverse personal
        finance topics. Our aim is to assist you in navigating the complexities
        of today's most critical financial issues
      </body>
      <br />
      <SectionTitle
        icon={DollarIcon}
        children={<h2 id="fraud">Personal Finance</h2>}
      />
      <h3>Personal Finance Articles</h3>
      <body>
        Courtesy of{' '}
        <ExternalLink href="https://marketwatch.com" title="MarketWatch.com" />
      </body>
      <br />
      <li>
        <ExternalLink
          href="https://www.marketwatch.com/story/these-22-cars-are-the-most-economical-to-own-2015-02-12"
          title="These 22 cars are the most economical to own"
        />
        <br />
        The sticker price on your car is only the beginning of what it really
        costs to own that auto — something that savvy shoppers should take into
        account when considering whether they got a deal or not
      </li>
      <br />
      <li>
        <ExternalLink
          href="https://www.marketwatch.com/story/most-americans-are-one-paycheck-away-from-the-street-2015-01-07"
          title="Most Americans are one paycheck away from the street"
        />
        <br />
        Americans are feeling better about their job security and the economy,
        but most are theoretically only one paycheck away from the street
      </li>
      <br />
      <li>
        <ExternalLink
          href="https://www.marketwatch.com/story/want-better-bank-rates-go-to-a-credit-union-2014-10-06"
          title="Want better bank rates? Go to a credit union"
        />
        <br />
        Credit unions charge 70% less than national banks and offer nearly 10
        times higher interest rates than regional banks, new research finds
      </li>
      <br />
      <SectionTitle
        icon={MagnifierIcon}
        children={<h2 id="personal">Debt Management</h2>}
      />
      <h3>Debt Management Articles</h3>
      <body>
        Courtesy of{' '}
        <ExternalLink href="https://marketwatch.com" title="MarketWatch.com" />
      </body>
      <br />
      <li>
        <ExternalLink
          href="https://www.marketwatch.com/story/how-to-raise-kids-who-know-the-value-of-a-dollar-2015-02-12"
          title="How to raise kids who know the value of a dollar"
        />
        <br />
        What's the best way to teach kids about money? Columnist Brett Arends
        finds some answers in a compelling new book
      </li>
      <br />
      <li>
        <ExternalLink
          href="https://www.marketwatch.com/story/condo-fee-foreclosures-become-headache-for-homeowners-2015-01-09"
          title="Condo-fee foreclosures become headache for homeowners"
        />
        <br />
        If you've fallen behind on your condo or homeowner association (HOA)
        fees, this might be a shocker for you: Your HOA or condo association
        might have the right to foreclose on your property
      </li>
      <br />
      <li>
        <ExternalLink
          href="https://www.marketwatch.com/story/buying-a-home-is-now-twice-as-affordable-as-renting-2014-12-11"
          title="Buying a home is now twice as affordable as renting"
        />
        <br />
        The American Dream of owning a home just got more affordable, but that's
        partly because the average American now spends nearly 30% of their
        income on rent
      </li>
      <br />
      <li>
        <ExternalLink
          href="https://www.marketwatch.com/story/10-things-your-health-insurance-wont-tell-you-2014-10-10"
          title="10 things your health insurance won't tell you"
        />
        <br />
        Obamacare is only part of the story: Changes in the health-insurance
        industry are having ripple effects for millions of consumers
      </li>
      <br />
      <SectionTitle
        icon={PlantIcon}
        children={<h2 id="alerts">Retirement</h2>}
      />
      <h3>Retirement Articles</h3>
      <body>
        Courtesy of{' '}
        <ExternalLink href="https://marketwatch.com" title="MarketWatch.com" />
      </body>
      <br />
      <li>
        <ExternalLink
          href="https://www.marketwatch.com/story/what-you-need-to-know-about-the-grandparent-tax-2015-02-12"
          title="What you need to know about the grandparent tax"
        />
        <br />
        How to avoid being stung by the GST tax
      </li>
      <br />
      <li>
        <ExternalLink
          href="https://www.marketwatch.com/story/the-secrets-of-successful-retirement-savers-2015-01-12"
          title="The secrets of successful retirement savers"
        />
        <br />
        If you're in your 40s, 50s or early 60s, odds are you'd like to know
        what it takes to have a happy and successful retirement
      </li>
      <br />
      <li>
        <ExternalLink
          href="https://www.marketwatch.com/story/a-retirement-crisis-is-coming-heres-how-to-fix-it-2014-12-09"
          title="A retirement crisis is coming- here's how to fix it"
        />
        <br />
        We are facing a crisis because of a disconnect between retirement needs
        and retirement resources
      </li>
      <br />
      <li>
        <ExternalLink
          href="https://www.marketwatch.com/story/year-end-retirement-planning-strategies-2014-10-10"
          title="Year-end retirement planning strategies"
        />
        <br />
        October marks the start of the fourth quarter: the perfect time to think
        about year-end planning strategies and tactics that can help save or
        make a buck or two. Though not an exhaustive list, here's what experts
        say you should consider or do now
      </li>
      <br />
    </CopywriteLayout>
  );
}
