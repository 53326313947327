import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

export default function FormStepper({ steps, activeStep, handleBack }) {
    const theme = useTheme();
    const maxSteps = steps?.length;
    // const [activeStep, setActiveStep] = React.useState(0);

    // const handleNext = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //     window.scrollTo(0, 0);
    // };

    // const handleBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    //     window.scrollTo(0, 0);
    // };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                // width: 315,
                // height: 275,
                // height: '100%',
                minHeight: '85vh',
                // backgroundColor: 'rgba(255, 255, 255, 0.9)',
                // boxShadow:
                //     '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
            }}
        >
            <Paper
                square
                elevation={0}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    // alignItems: 'center',
                    // height: 50,
                    // pl: 2,
                    bgcolor: 'background.default',
                }}
            >
                <Typography fontSize={30}>{steps[activeStep].label}</Typography>
            </Paper>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    // alignItems: 'center',
                    // height: 255,
                    // maxWidth: 400,
                    // width: '100%',
                    // p: 1,
                }}
            >
                {steps[activeStep].description}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    // alignItems: 'center',
                    // height: 255,
                    // maxWidth: 400,
                    // width: '100%',
                    flexGrow: 0.8,
                    // minHeight: '55vh',
                    // height: '100%',
                    // minHeight: { xs: 400, sm: 500, md: 600 },
                    p: 3,
                }}
            >
                {steps[activeStep].content}
            </Box>
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                }}
            >
                <MobileStepper
                    variant="dots"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button
                            type="submit"
                            size="small"
                            disabled={activeStep === maxSteps - 1}
                        >
                            Next
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button
                            size="small"
                            onClick={handleBack}
                            disabled={activeStep === 0}
                        >
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                            Back
                        </Button>
                    }
                />
            </Box>
        </Box>
    );
}
