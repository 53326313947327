import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    backgroundColor: 'rgba(255, 255, 255, 0.0)',
    // ...theme.typography.body2,
    ...theme.typography.body1,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none', // toggle for development
    borderRadius: 0,
}));

export default function ProductCard({
    title,
    label,
    icon,
    buttonLabel,
    href,
    iconOffset,
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                // width: 315,
                // height: 275,
                height: '100%',
                // backgroundColor: 'rgba(255, 255, 255, 0.9)',
                padding: 3,
                borderRadius: '15px',
                boxShadow:
                    '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    component="img"
                    src={icon}
                    alt="increase priority"
                    marginLeft={iconOffset} // PlantIcon is off center
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        width: {
                            xs: 80,
                            sm: 65,
                            md: 70,
                            lg: 80,
                        },
                    }}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                <Item
                    sx={{
                        fontSize: {
                            xs: 20,
                            sm: 22,
                            md: 16,
                            lg: 18,
                        },
                        fontWeight: 'bold',
                    }}
                >
                    {title}
                </Item>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                <Item
                    sx={{
                        fontSize: {
                            xs: 13,
                            sm: 13,
                            md: 12,
                            lg: 12,
                        },
                    }}
                >
                    {label}
                </Item>
            </Box>
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}
            >
                <Item>
                    <Button
                        variant="outlined"
                        size="sm"
                        href={href}
                        sx={{
                            // marginTop: 2,
                            fontSize: 10,
                            borderRadius: 5,
                            // backgroundColor:
                            //     'rgb(255, 200, 87, 0.85)',
                            boxShadow: 'none',
                            '&:hover': {
                                backgroundColor: 'rgb(5, 110, 139, 1.0)',
                                color: 'common.white',
                                borderColor: 'rgb(5, 110, 139, 1.0)',
                                boxShadow:
                                    '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
                            },
                        }}
                    >
                        {buttonLabel}
                    </Button>
                </Item>
            </Box>
        </Box>
    );
}
