import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const drawerContent = [
    { name: 'Accounts', href: '/accounts' },
    { name: 'Loans & Mortgages', href: '/loans' },
    { name: 'Services', href: '/services' },
    { name: 'Education', href: '/education' },
    { name: 'Resources', href: '/resources' },
];

const drawerFooter = [
    { name: 'Help', href: '/resources' },
    { name: 'Legal', href: '/resources' },
];

export default function MobileDrawer() {
    const [state, setState] = useState(false);

    const toggleDrawer = (state) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState(state);
    };

    const list = () => (
        <Box
            sx={{
                width: 250,
            }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <Box padding={2.5} marginLeft="10px">
                <CloseIcon />
            </Box>
            <List>
                {drawerContent.map((item, index) => (
                    <ListItem key={index + 1}>
                        <ListItemButton href={item.href}>
                            {/* <ListItemIcon>{item.icon}</ListItemIcon> */}
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />

            <List>
                {drawerFooter.map((item, index) => (
                    <ListItem key={index + 1}>
                        <ListItemButton href={item.href}>
                            {/* <ListItemIcon>{item.icon}</ListItemIcon> */}
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <>
            <Button
                onClick={() => setState(true)}
                size="large"
                color="inherit"
                aria-label="menu"
                disableRipple
                sx={{
                    '&:hover': {
                        color: 'rgb(47, 108, 136, 1.0)',
                        backgroundColor: 'common.white',
                    },
                    '.MuiButtonBase-root:disabled': {
                        cursor: 'not-allowed',
                        pointerEvents: 'auto',
                    },
                }}
            >
                <MenuIcon fontSize="large" />
            </Button>
            <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer>
        </>
    );
}
