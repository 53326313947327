import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function LinkCard({ title, label, icon, href }) {
    return (
        <Box
            component="a"
            href={href}
            sx={{
                textDecoration: 'none',
                color: 'common.black',
                display: 'flex',
                // width: 315,
                // height: 275,
                // backgroundColor: 'rgba(255, 255, 255, 0.9)',
                paddingX: {
                    md: 1,
                    lg: 3,
                },
                paddingY: {
                    md: 2,
                    lg: 3,
                },
                borderRadius: '15px',
                '&:hover': {
                    cursor: 'pointer',
                    boxShadow:
                        '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
                },
            }}
        >
            <Box display="flex" justifyContent="flex-start" alignItems="center">
                <img src={icon} alt="check" width={40} height={40} />
            </Box>
            <Box width="100%" marginLeft={2}>
                <Typography>
                    <b>
                        <i>{title}</i>
                    </b>
                </Typography>
                <Typography variant="body2" fontSize={12}>
                    {label}
                </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
                <ArrowForwardIcon />
            </Box>
        </Box>
    );
}
