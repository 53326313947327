// import * as React from 'react';
import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CompanyLogo from '../../assets/hiland_transparent.png';

const MobileToolbar = styled(Toolbar)(({ theme }) => ({
    height: 68,
    // [theme.breakpoints.up('sm')]: {
    //     height: 70,
    // },
    // display: 'flex',
    // margin: 'auto',
    // justifyContent: 'center',
    // alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    // Override media queries injected by theme.mixins.toolbar
    '@media all': {
        minHeight: 68,
    },
    borderBottom: '3px solid rgba(0, 35, 80, 0.9)',
}));

const DesktopToolbar = styled(Toolbar)(({ theme }) => ({
    height: 96,
    // [theme.breakpoints.up('sm')]: {
    //     height: 70,
    // },
    // display: 'flex',
    // margin: 'auto',
    // justifyContent: 'center',
    // alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    // Override media queries injected by theme.mixins.toolbar
    '@media all': {
        minHeight: 96,
    },
    borderBottom: '3px solid rgba(0, 35, 80, 0.9)',
}));

export default function SimpleAppBar() {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                elevation={0}
                position="static"
                sx={{
                    bgcolor: 'common.white',
                    color: 'inherit',
                }}
            >
                <MobileToolbar
                    // disableGutters
                    sx={{
                        marginTop: 1.5,
                        display: {
                            xs: 'flex',
                            sm: 'flex',
                            md: 'none',
                        },
                    }}
                >
                    <Box
                        component="a"
                        href="/"
                        sx={{
                            flexGrow: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            textDecoration: 'none',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                    >
                        <img
                            src={CompanyLogo}
                            alt="Hi-Land Credit Union"
                            width={64}
                        />
                    </Box>
                </MobileToolbar>
                <DesktopToolbar
                    disableGutters
                    sx={{
                        marginTop: 1.5,
                        display: {
                            xs: 'none',
                            sm: 'none',
                            md: 'flex',
                        },
                    }}
                >
                    <Box
                        component="a"
                        href="/"
                        sx={{
                            flexGrow: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            textDecoration: 'none',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                    >
                        <img
                            src={CompanyLogo}
                            alt="Hi-Land Credit Union"
                            width={96}
                        />
                    </Box>
                </DesktopToolbar>
            </AppBar>
        </Box>
    );
}
