import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function createData(name, maximum_ltv, apr) {
  return { name, maximum_ltv, apr };
}

const rows = [
  createData('5 Year', '75%', '8.15%'),
  createData('10 Year', '75%', '8.40%'),
  createData('15 Year', '75%', '8.99%'),
];

export default function FixedSecondMortgageTable() {
  return (
    <TableContainer>
      <Table stickyHeader={true} aria-label="table">
        <TableHead>
          <TableRow>
            <TableCell>Mortgage Term</TableCell>
            <TableCell align="right">Maximum LTV</TableCell>
            <TableCell align="right">APR</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0,
                },
              }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.maximum_ltv}</TableCell>
              <TableCell align="right">{row.apr}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
