import React from 'react';
import Box from '@mui/material/Box';
import CopywriteLayout from '../components/layouts/CopywriteLayout';

import MessageIcon from '../assets/icons/actions/message.png';
import StopwatchIcon from '../assets/icons/actions/stopwatch.png';

const SectionTitle = ({ icon, children }) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }}
    >
        <Box
            style={{
                display: 'flex',
                marginRight: '10px',
            }}
        >
            <img src={icon} alt="" width={25} height={25} />
        </Box>
        {children}
    </div>
);

export default function ContactPage() {
    return (
        <CopywriteLayout>
            <br />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <h1>Hi-Land Credit Union</h1>
                <body style={{ fontSize: 19 }}>
                    Need assistance? Give us a call today: (801) 261-8909 <br />
                </body>
            </div>
            <br />
            <SectionTitle
                icon={MessageIcon}
                children={<h2 id="home">Contact Information</h2>}
            />
            <br />
            <body>
                <b>Hi-Land Credit Union</b> <br />
                5366 South Executive Park Drive <br />
                Murray, Utah 84117
            </body>
            <br />
            <body>
                <b>Phone:</b> (801) 261-8909 <br />
                <b>Fax:</b> (801) 261-2302 <br />
                <b>E-mail:</b> hiland@hilandcu.com
            </body>
            <br />
            <SectionTitle
                icon={StopwatchIcon}
                children={<h2 id="auto">Hours of Operation</h2>}
            />
            <br />
            <body>
                <b>Lobby Hours</b>
                <br />
                <b>Monday to Friday:</b> 9:00 am to 4:30 pm <br />
            </body>
            <br />
            <body>
                <b>Drive-thru Hours</b>
                <br />
                <b>Monday to Friday:</b> 9:00 am to 5:30 pm <br />
            </body>
            <br />
            <body>
                <b>Saturday-Sunday:</b> Closed
            </body>
            <br />
        </CopywriteLayout>
    );
}
