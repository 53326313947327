import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function createData(name, minimum_balance, dividend_rate, apy) {
  return { name, minimum_balance, dividend_rate, apy };
}

const rows = [
  createData('Regular Shares', '$0', '0.20%', '0.20%'),
  createData('Regular Shares', '$4,000', '0.31%', '0.31%'),
  createData('Regular Shares', '$10,000', '0.35%', '0.35%'),
  createData('Regular Shares', '$25,000+', '0.35%', '0.35%'),
  createData('Checking', '$500', '0.05%', '0.05%'),
  createData('IRA - Roth & Traditional', '$1', '0.99%', '1.00%'),
];

export default function SharesTable() {
  return (
    <TableContainer>
      <Table stickyHeader={true} aria-label="table">
        <TableHead>
          <TableRow>
            <TableCell>Savings Plan</TableCell>
            <TableCell align="right">Minimum Balance</TableCell>
            <TableCell align="right">Dividend Rate</TableCell>
            <TableCell align="right">APY</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0,
                },
              }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.minimum_balance}</TableCell>
              <TableCell align="right">{row.dividend_rate}</TableCell>
              <TableCell align="right">{row.apy}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
