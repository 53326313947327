import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import Grid from '@mui/material/Grid';

import CompanyLogo from '../../assets/hiland_transparent.png';

import AccountsMenu from './menus/AccountsMenu';
import LoansMenu from './menus/LoansMenu';
import ServicesMenu from './menus/ServicesMenu';
import ResourcesMenu from './menus/ResourcesMenu';

import PopoverHTML from './menus/PopoverHTML';
import MobileDrawer from './MobileDrawer';

import Alert from '@mui/material/Alert';
// import { Tooltip } from '@mui/material';

const expirationAlert1 = new Date('2024-11-29T23:00:00').toLocaleString(
  'en-US',
  {
    timeZone: 'America/Denver',
  }
);

const commenceDateAnnualMeeting = new Date(
  '2024-11-15T01:00:00'
).toLocaleString('en-US', {
  timeZone: 'America/Denver',
});
const expirationDateAnnualMeeting = new Date(
  '2024-11-29T23:00:00'
).toLocaleString('en-US', {
  timeZone: 'America/Denver',
});

// const mobileButtons = {
//     fontSize: 12,
//     color: 'common.black',
//     borderRadius: 5,
//     textTransform: 'none',
//     flexShrink: 0,
//     '&:hover': {
//         backgroundColor: '#5D86A0',
//         color: 'common.white',
//         boxShadow: 'none',
//     },
//     // visibility: {
//     //     xs: 'visible',
//     //     sm: 'visible',
//     //     md: 'hidden',
//     //     lg: 'hidden',
//     //     xl: 'hidden',
//     // },

//     // '&:active': {
//     //     boxShadow: 'none',
//     //     backgroundColor: '#3c52b2',
//     // },
// };

const menuButtons = {
  fontSize: 12,
  color: 'common.black',
  ml: 3,
  // mr: '5%',
  borderRadius: 5,
  textTransform: 'none',
  flexShrink: 0,
  '&:hover': {
    backgroundColor: 'rgb(5, 110, 139, 1.0)',
    color: 'common.white',
    borderColor: 'rgb(5, 110, 139, 1.0)',
    boxShadow:
      '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
  },

  // '&:active': {
  //     boxShadow: 'none',
  //     backgroundColor: '#3c52b2',
  // },
};

const menuItems = {
  fontSize: 12,
  color: 'common.black',

  // ml: 3,
  // mr: '10%',
  // borderRadius: 5,
  textTransform: 'none',
  flexShrink: 0,
  '&:hover': {
    textDecoration: 'underline',
    backgroundColor: 'common.white',
    // color: 'common.black',
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: 'none',
    color: 'none',
    boxShadow: 'none',
  },
  // visibility: {
  //     xs: 'hidden',
  //     sm: 'hidden',
  //     md: 'visible',
  //     lg: 'visible',
  //     xl: 'visible',
  // },
};

// const Toolbar = styled(MuiToolbar)(({ theme }) => ({
//     height: 64,
//     [theme.breakpoints.up('sm')]: {
//         height: 70,
//     },
// }));

const MobileToolbar = styled(Toolbar)(({ theme }) => ({
  height: 64,
  // [theme.breakpoints.up('sm')]: {
  //     height: 70,
  // },
  // display: 'flex',
  // margin: 'auto',
  // justifyContent: 'center',
  // alignItems: 'flex-start',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  // Override media queries injected by theme.mixins.toolbar
  '@media all': {
    minHeight: 68,
  },
  borderBottom: '3px solid rgba(0, 35, 80, 0.9)',
}));

const DesktopToolbar = styled(Toolbar)(({ theme }) => ({
  height: 64,
  // [theme.breakpoints.up('sm')]: {
  //     height: 70,
  // },
  // display: 'flex',
  // margin: 'auto',
  // justifyContent: 'center',
  // alignItems: 'flex-start',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  // Override media queries injected by theme.mixins.toolbar
  '@media all': {
    minHeight: 128,
  },
  borderBottom: '3px solid rgba(0, 35, 80, 0.9)',
}));

export default function MainAppBar() {
  const [currentTime, setCurrentTime] = useState(null);

  useEffect(() => {
    setCurrentTime(
      new Date().toLocaleString('en-US', { timeZone: 'America/Denver' })
    );
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Alert
        variant="filled"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          bgcolor: 'background.paper',
          color: 'rgb(0, 88, 157, 1.0)',
          p: 0,
          marginX: 2,
          marginTop: 1,
          boxShadow: 'none',
        }}
      >
        REMINDER: Hi-Land Credit Union will never reach out requesting personal
        information
      </Alert>
      {/* Alert 1 - Labor day */}
      {new Date(currentTime) < new Date(expirationAlert1) ? (
        <Alert
          variant="filled"
          icon={false}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            bgcolor: 'background.paper',
            color: 'rgb(0, 88, 157, 1.0)',
            p: 0,
            marginX: 2,
            marginTop: 1,
            boxShadow: 'none',
          }}
        >
          The Credit Union will be closed November 28th and 29th in observance
          of Thanksgiving
        </Alert>
      ) : null}
      {/* Alert 2 - Annual Meeting */}
      {(new Date(commenceDateAnnualMeeting) < new Date(currentTime)) &
      (new Date(currentTime) < new Date(expirationDateAnnualMeeting)) ? (
        <Alert
          variant="filled"
          icon={false}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            bgcolor: 'background.paper',
            color: 'rgb(0, 88, 157, 1.0)',
            p: 0,
            marginX: 2,
            marginTop: 1,
            boxShadow: 'none',
          }}
        >
          We will be closed Thanksgiving Day, November 28th and Friday, November
          29th
          {/* <Tooltip
            // title="Nominations for candidacy on the board must be submitted to Hi-Land Credit Union by October 4, 2024. Voting will commence on Monday, October 7, and will run through Friday, October 11."
            title="Join us for an Open House on Friday, October 11th! Stop by between 2:00 and 4:30 PM to enjoy refreshments and a chance to win door prizes"
            placement="bottom"
            arrow
            enterTouchDelay={0}
            leaveTouchDelay={15000} // tooltip will dissapear after 15 seconds on mobile (or with outside click)
            leave
          >
            <label>
              <u>Open House Details</u>
            </label>
          </Tooltip> */}
        </Alert>
      ) : null}
      <AppBar
        elevation={0}
        position="static"
        sx={{
          bgcolor: 'common.white',
          color: 'inherit',
        }}
      >
        <MobileToolbar
          // disableGutters
          sx={{
            marginTop: 1.5,
            display: {
              xs: 'flex',
              sm: 'flex',
              md: 'none',
            },
          }}
        >
          <Box
            component="a"
            href="/"
            sx={{
              flexGrow: 1,
              marginLeft: 1.5,
              textDecoration: 'none',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            <img src={CompanyLogo} alt="Hi-Land Credit Union" width={64} />
          </Box>
          <Button
            color="inherit"
            variant="outlined"
            // underline="none"
            href="/signin"
            sx={{ ...menuButtons, marginRight: 1 }}
          >
            Sign In
          </Button>
          <MobileDrawer />
        </MobileToolbar>
        <DesktopToolbar
          disableGutters
          sx={{
            marginTop: 1.5,
            display: {
              xs: 'none',
              sm: 'none',
              md: 'flex',
            },
          }}
        >
          <Box
            component="a"
            href="/"
            sx={{
              flexGrow: 1,
              marginLeft: 1.5,
              textDecoration: 'none',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            <img src={CompanyLogo} alt="Hi-Land Credit Union" width={128} />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 1,
                  marginRight: 1.5,
                }}
              >
                <Button
                  disableRipple
                  // color="inherit"
                  // variant="body1"
                  // underline="none"
                  href="https://co-opcreditunions.org/locator/"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={menuItems}
                  // sx={{ ...rightLink, color: 'secondary.main' }}
                >
                  ATMs/Locations
                </Button>
                <Button
                  href="/resources"
                  disableRipple
                  color="inherit"
                  // variant="body1"
                  // underline="none"
                  // href="/"
                  sx={menuItems}
                  // sx={{ ...rightLink, color: 'secondary.main' }}
                >
                  Help
                </Button>
                <Button
                  href="/resources#about"
                  disableRipple
                  color="inherit"
                  // variant="body2"
                  // underline="none"
                  // href="/"
                  sx={menuItems}
                  // sx={{ ...rightLink, color: 'secondary.main' }}
                >
                  About Us
                </Button>
                <Button
                  disableRipple
                  color="inherit"
                  // variant="body2"
                  // underline="none"
                  href="/contact"
                  sx={menuItems}
                  // sx={{ ...rightLink, color: 'secondary.main' }}
                >
                  Contact
                </Button>
                <Button
                  color="inherit"
                  variant="outlined"
                  // underline="none"
                  href="/signin"
                  sx={menuButtons}
                >
                  Sign In
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'flex-start',
                  margin: 'auto',
                  marginLeft: '5%',
                }}
              >
                <PopoverHTML>
                  <AccountsMenu title="Accounts" href="/accounts" />
                  <LoansMenu title="Loans & Mortgages" href="/loans" />
                  <ServicesMenu title="Services" href="/services" />
                  <ResourcesMenu
                    title="Resources & Education"
                    href="/resources"
                  />
                </PopoverHTML>
              </Box>
            </Grid>
          </Grid>
        </DesktopToolbar>
      </AppBar>
    </Box>
  );
}
