import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import LiteAppBar from '../../nav/LiteAppBar';
import LiteAppFooter from '../../nav/LiteAppFooter';

export default function FormSubmittedPage() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const error = urlParams.has('error');
    return (
        <Box>
            <LiteAppBar />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '75px',
                    height: '62vh',
                }}
            >
                {error ? (
                    <Typography fontSize={30}>
                        There was an error submitting your application. <br />
                        <br />
                        Please try again or give us a call at:{' '}
                        <b>(801) 261-8909</b>
                    </Typography>
                ) : (
                    <Typography fontSize={30}>
                        Thank you for your submitting your application, we'll be
                        in touch shortly. <br />
                        <br /> Please feel free to give us a call if you have
                        any questions in the mean time: <b>(801) 261-8909</b>
                    </Typography>
                )}
            </Box>
            <LiteAppFooter />
        </Box>
    );
}
