import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

// Input formatting
import { Controller } from 'react-hook-form';
import { NumericFormat, PatternFormat } from 'react-number-format';

const coApplicantTypeOptions = [
    {
        value: 'false',
        label: 'No',
    },
    {
        value: 'spouse',
        label: 'Spouse',
    },
    {
        value: 'guarantor',
        label: 'Guarantor',
    },
];

const marriageStatusOptions = [
    {
        value: 'single',
        label: 'Single (Never Married)',
    },
    {
        value: 'married',
        label: 'Married',
    },
    {
        value: 'partnership',
        label: 'Domestic Partnership',
    },
    {
        value: 'divorced',
        label: 'Divorced',
    },
    {
        value: 'widowed',
        label: 'Widowed',
    },
];

const homeOwnershipStatusOptions = [
    {
        value: 'own',
        label: 'Own',
    },
    {
        value: 'rent',
        label: 'Rent',
    },
];

export default function SecondaryLoaneeForm({ control, coApplicantWatch }) {
    return (
        <Box>
            <>
                <Typography
                    width="100%"
                    fontSize={{ xs: 16, sm: 20 }}
                    marginY={{ xs: 2, sm: 1.5, md: 2 }}
                >
                    Are you applying with someone else?
                </Typography>
                <Controller
                    name="coApplicant.applicant"
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState: { invalid, error } }) => (
                        <TextField
                            {...field}
                            error={invalid}
                            helperText={error?.message}
                            select
                            fullWidth
                            label="Co-Applicant"
                        >
                            {coApplicantTypeOptions.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                    id={option.value}
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                />
            </>
            {coApplicantWatch === '' || coApplicantWatch === 'false' ? null : (
                <>
                    <Divider sx={{ marginY: 5 }}>
                        <Typography fontSize={{ xs: 16, sm: 20 }}>
                            Co-Applicant Information
                        </Typography>
                    </Divider>
                    <Grid
                        container
                        spacing={{ xs: 1, sm: 1.5, md: 2 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                    >
                        <Grid item xs={4} sm={8} md={12}>
                            <Typography fontSize={{ xs: 16, sm: 20 }}>
                                Personal
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sm={8} md={12}>
                            <Controller
                                name="coApplicant.personal.marriageStatus"
                                control={control}
                                defaultValue=""
                                render={({
                                    field,
                                    fieldState: { invalid, error },
                                }) => (
                                    <TextField
                                        {...field}
                                        error={invalid}
                                        helperText={error?.message}
                                        select
                                        fullWidth
                                        label="Marriage Status"
                                    >
                                        {marriageStatusOptions.map((option) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                                id={option.value}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            />
                        </Grid>
                        <Grid item xs={4} sm={8} md={12}>
                            <Controller
                                name="coApplicant.personal.firstName"
                                control={control}
                                defaultValue=""
                                render={({
                                    field,
                                    fieldState: { invalid, error },
                                }) => (
                                    <TextField
                                        {...field}
                                        error={invalid}
                                        helperText={error?.message}
                                        fullWidth
                                        label="First Name"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4} sm={8} md={12}>
                            <Controller
                                name="coApplicant.personal.middleName"
                                control={control}
                                defaultValue=""
                                render={({
                                    field,
                                    fieldState: { invalid, error },
                                }) => (
                                    <TextField
                                        {...field}
                                        error={invalid}
                                        helperText={error?.message}
                                        fullWidth
                                        label="Middle Name (optional)"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4} sm={8} md={12}>
                            <Controller
                                name="coApplicant.personal.lastName"
                                control={control}
                                defaultValue=""
                                render={({
                                    field,
                                    fieldState: { invalid, error },
                                }) => (
                                    <TextField
                                        {...field}
                                        error={invalid}
                                        helperText={error?.message}
                                        fullWidth
                                        label="Last Name"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4} sm={8} md={12}>
                            <Typography fontSize={{ xs: 16, sm: 20 }}>
                                Contact
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sm={8} md={12}>
                            <Controller
                                name="coApplicant.contact.phone"
                                control={control}
                                defaultValue=""
                                render={({
                                    field: { ref, onChange, name, value },
                                    fieldState: { invalid, error },
                                }) => (
                                    <PatternFormat
                                        inputRef={ref}
                                        name={name}
                                        value={value}
                                        onChange={onChange}
                                        error={invalid}
                                        helperText={error?.message}
                                        label="Phone Number"
                                        format="(###) ###-####"
                                        fullWidth
                                        customInput={TextField}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4} sm={8} md={12}>
                            <Controller
                                name="coApplicant.contact.email"
                                control={control}
                                defaultValue=""
                                render={({
                                    field,
                                    fieldState: { invalid, error },
                                }) => (
                                    <TextField
                                        {...field}
                                        error={invalid}
                                        helperText={error?.message}
                                        fullWidth
                                        label="Email Address"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4} sm={8} md={12}>
                            <Typography fontSize={{ xs: 16, sm: 20 }}>
                                Residential
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sm={8} md={12}>
                            <Controller
                                name="coApplicant.address.ownership"
                                control={control}
                                defaultValue=""
                                render={({
                                    field,
                                    fieldState: { invalid, error },
                                }) => (
                                    <TextField
                                        {...field}
                                        error={invalid}
                                        helperText={error?.message}
                                        select
                                        fullWidth
                                        label="Rent or Own"
                                    >
                                        {homeOwnershipStatusOptions.map(
                                            (option) => (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                    id={option.value}
                                                >
                                                    {option.label}
                                                </MenuItem>
                                            )
                                        )}
                                    </TextField>
                                )}
                            />
                            <Grid item xs={4} sm={8} md={12}>
                                <Controller
                                    name="applicant.address.residenceYears"
                                    control={control}
                                    defaultValue=""
                                    render={({
                                        field: { ref, onChange, name, value },
                                        fieldState: { invalid, error },
                                    }) => (
                                        <NumericFormat
                                            inputRef={ref}
                                            name={name}
                                            value={value}
                                            onChange={onChange}
                                            error={invalid}
                                            helperText={error?.message}
                                            label="Years of Residence"
                                            fullWidth
                                            // thousandSeparator
                                            // prefix=""
                                            customInput={TextField}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={4} sm={8} md={12}>
                            <Controller
                                name="coApplicant.address.street"
                                control={control}
                                defaultValue=""
                                render={({
                                    field,
                                    fieldState: { invalid, error },
                                }) => (
                                    <TextField
                                        {...field}
                                        error={invalid}
                                        helperText={error?.message}
                                        fullWidth
                                        label="Address"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4} sm={8} md={12}>
                            <Controller
                                name="coApplicant.address.street2"
                                control={control}
                                defaultValue=""
                                render={({
                                    field,
                                    fieldState: { invalid, error },
                                }) => (
                                    <TextField
                                        {...field}
                                        error={invalid}
                                        helperText={error?.message}
                                        fullWidth
                                        label="Apartment, suite, etc. (optional)"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <Controller
                                name="coApplicant.address.city"
                                control={control}
                                defaultValue=""
                                render={({
                                    field,
                                    fieldState: { invalid, error },
                                }) => (
                                    <TextField
                                        {...field}
                                        error={invalid}
                                        helperText={error?.message}
                                        fullWidth
                                        label="City"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <Controller
                                name="coApplicant.address.state"
                                control={control}
                                defaultValue=""
                                render={({
                                    field,
                                    fieldState: { invalid, error },
                                }) => (
                                    <TextField
                                        {...field}
                                        error={invalid}
                                        helperText={error?.message}
                                        fullWidth
                                        label="State"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <Controller
                                name="coApplicant.address.zip"
                                control={control}
                                defaultValue=""
                                render={({
                                    field,
                                    fieldState: { invalid, error },
                                }) => (
                                    <TextField
                                        {...field}
                                        error={invalid}
                                        helperText={error?.message}
                                        fullWidth
                                        label="Zip"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={12}>
                            <Controller
                                name="coApplicant.address.country"
                                control={control}
                                defaultValue=""
                                render={({
                                    field,
                                    fieldState: { invalid, error },
                                }) => (
                                    <TextField
                                        {...field}
                                        error={invalid}
                                        helperText={error?.message}
                                        fullWidth
                                        label="Country"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </Box>
    );
}
