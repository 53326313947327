import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';

import GolfImage from '../../assets/stock_photos/golf.png';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    backgroundColor: 'rgba(255, 255, 255, 0.0)',
    // ...theme.typography.body2,
    ...theme.typography.body1,
    // padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    boxShadow: 'none', // toggle for development
    borderRadius: 0,
}));

const BasicLayoutRoot = styled('section')(({ theme }) => ({
    color: theme.palette.common.white,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('xs')]: {
        height: '80vh',
        minHeight: 200,
        maxHeight: 300,
    },
    [theme.breakpoints.up('sm')]: {
        height: '80vh',
        minHeight: 350,
        maxHeight: 400,
    },
}));

export default function PromoContainer() {
    return (
        <Box>
            <BasicLayoutRoot
                style={{
                    backgroundImage: `url(${GolfImage})`,
                    backgroundColor: '#737373', // Average color of the background image.
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover', // contain or cover
                    backgroundPosition: 'center',
                }}
            >
                <Box
                    style={{
                        height: '100%',
                        width: '100%',
                        // backgroundColor: 'rgba(171, 204, 217, 0.3)',
                        // backgroundColor: 'rgba(255, 255, 255, 0.3)',
                        backgroundColor: 'rgba(5, 110, 139, 0.2)',
                        // backgroundColor: 'rgba(5, 110, 139, 0.6)',
                    }}
                >
                    <Container
                        sx={{
                            // mt: 3,
                            // mb: 14,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        {/* Increase the network loading priority of the background image. */}
                        <img
                            style={{ display: 'none' }}
                            src={GolfImage}
                            alt="increase priority"
                        />
                        <Grid container spacing={2} sx={{ marginTop: '7%' }}>
                            <Grid
                                item
                                md
                                sm
                                // xs={4}
                                sx={{
                                    marginLeft: {
                                        md: '3%',
                                    },
                                    backgroundColor: 'rgba(255, 255, 255, 0.0)',
                                }}
                            >
                                <Item
                                    sx={{
                                        fontSize: {
                                            xs: 25,
                                            sm: 40,
                                            md: 50,
                                            lg: 60,
                                        },
                                        fontWeight: 'bold',
                                        color: '#ffffff',
                                    }}
                                >
                                    Invest In Your Future
                                </Item>
                                <Item
                                    sx={{
                                        fontSize: {
                                            xs: 12,
                                            sm: 16,
                                            md: 20,
                                            lg: 25,
                                        },
                                        padding: 0,
                                        // color: '#000000',
                                        color: '#ffffff',
                                        width: '60%',
                                    }}
                                >
                                    Competitive CD rates with flexible terms,
                                    our team works to best serve your financial
                                    goals
                                </Item>
                                <Item>
                                    <Typography
                                        align="left"
                                        sx={{ paddingLeft: 0 }}
                                    >
                                        <Button
                                            href="/accounts#cd"
                                            variant="contained"
                                            sx={{
                                                marginTop: 2,
                                                borderRadius: 5,
                                                backgroundColor:
                                                    'rgb(255, 200, 87, 1.0)',
                                                '&:hover': {
                                                    backgroundColor:
                                                        'rgb(255, 200, 87, 1.0)',
                                                },
                                            }}
                                        >
                                            Get Started Today
                                        </Button>
                                    </Typography>
                                </Item>
                            </Grid>
                        </Grid>
                        {/* <Typography
                color="inherit"
                align="center"
                variant="h2"
                marked="center"
            >
                Upgrade your Sundays
            </Typography>
            <Typography
                color="inherit"
                align="center"
                variant="h5"
                sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
            >
                Enjoy secret offers up to -70% off the best luxury hotels every
                Sunday.
            </Typography>
            <Button
                color="secondary"
                variant="contained"
                size="large"
                component="a"
                href="/premium-themes/onepirate/sign-up/"
                sx={{ minWidth: 200 }}
            >
                Register
            </Button>
            <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
                Discover the experience
            </Typography> */}
                    </Container>
                </Box>
            </BasicLayoutRoot>
        </Box>
    );
}
