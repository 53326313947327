import React from 'react';
import Box from '@mui/material/Box';
import CopywriteLayout from '../components/layouts/CopywriteLayout';

import ComputerIcon from '../assets/icons/banking/computer.png';
import CalendarIcon from '../assets/icons/banking/calendar.png';
import AgreementIcon from '../assets/icons/banking/agreement.png';
import CloudIcon from '../assets/icons/banking/cloud.png';
import AtmIcon from '../assets/icons/banking/atm.png';
import MachineIcon from '../assets/icons/banking/machine.png';

const SectionTitle = ({ icon, children }) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }}
    >
        <Box
            style={{
                display: 'flex',
                marginRight: '10px',
            }}
        >
            <img src={icon} alt="" width={40} height={40} />
        </Box>
        {children}
    </div>
);

export default function ServicesPage() {
    return (
        <CopywriteLayout>
            <br />
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <h1>Services at Hi-Land Credit Union</h1>
            </div>
            <br />
            <SectionTitle
                icon={ComputerIcon}
                children={<h2 id="olb">Mobile Banking</h2>}
            />
            <br />
            <body>
                Hi-Land Credit Union elevates your banking experience with the
                utmost convenience through our robust Online Banking services.
                Paying your bills is now a breeze with our user-friendly Online
                Bill Pay, eliminating the need for checks and stamps. Whether
                it's handling single bills for one-time purchases or managing
                recurring bills like utilities and mortgages, you have the
                flexibility to schedule payments and easily review your payment
                history.
            </body>
            <br />
            <body>
                Experience the freedom of managing your finances anytime,
                anywhere with our comprehensive Online Banking platform. Access
                account balances and history, perform fund transfers, make loan
                payments, and even apply for loans—all at your fingertips. Our
                Flex-Teller online account access system takes the hassle out of
                adhering to traditional business hours, allowing you to check
                balances, transfer funds, and apply for loans whenever it suits
                you.
            </body>
            <br />
            <body>
                For those who prefer managing finances on the go, our Mobile
                Teller service brings the power of Online Banking to your
                smartphone. Access account information, review transaction
                history, make loan payments, transfer funds, utilize Bill Pay,
                and even deposit checks remotely—all through our Mobile Teller
                feature. Simply visit hilandcu.com on your web-enabled mobile
                device, log in with your Mobile Teller account and password, and
                enjoy seamless financial management tailored to your dynamic
                lifestyle.
            </body>
            <br />
            <body>
                Already a member? <a href="/signin">Sign in now</a>
            </body>
            <br />
            <SectionTitle
                icon={CalendarIcon}
                children={<h2 id="loanmgmt">Loan Management</h2>}
            />
            <br />
            <body>
                Hi-Land Credit Union empowers you with efficient and
                personalized loan management tools, ensuring a seamless and
                stress-free experience throughout the life of your loans. Our
                user-friendly loan management platform allows you to
                effortlessly stay on top of your financial commitments.
            </body>
            <br />
            <body>
                With our online loan management system, you gain control over
                your credit union accounts beyond traditional business hours.
                Whether you want to check your account balances, transfer funds,
                or make loan payments, our Mobile Teller service is available
                day or night from virtually anywhere. Applying for a loan is
                also made convenient, providing a streamlined process for your
                financial needs.
            </body>
            <br />
            <body>
                For those on the move, our Mobile Teller service extends the
                flexibility of loan management to your fingertips. Accessible
                from your smartphones, Mobile Teller allows you to check account
                balances, review transaction history, make loan payments,
                transfer funds, and utilize remote deposit capture, ensuring you
                have full control of your financial activities wherever life
                takes you.
            </body>
            <br />
            <body>
                Hi-Land Credit Union is committed to providing you with the
                tools you need to manage your loans effectively, ensuring your
                financial journey is as smooth as possible.
            </body>
            <br />
            <SectionTitle
                icon={AgreementIcon}
                children={<h2 id="billpay">Bill Pay</h2>}
            />
            <br />
            <body>
                Simplify your financial life with Hi-Land Credit Union's Online
                Bill Pay service, offering you a convenient and efficient way to
                manage your payments. No more checks to write or stamps to
                lick—our Bill Pay feature allows you to settle your bills
                automatically, providing you with flexibility and ease.
            </body>
            <br />
            <body>
                Whether it's a one-time purchase or recurring bills like
                utilities, mortgage, or credit cards, Hi-Land's Bill Pay lets
                you schedule payments based on your preferences. Take control of
                your financial obligations by reviewing your payment history at
                your convenience.
            </body>
            <br />
            <body>
                With Hi-Land's commitment to meeting your needs, our Online Bill
                Pay is designed to make paying bills a hassle-free experience.
                Stay organized and in control of your finances with this
                user-friendly service, making your payment process seamless and
                stress-free.
            </body>
            <br />
            <SectionTitle
                icon={CloudIcon}
                children={<h2 id="rdc">Remote Deposit</h2>}
            />
            <br />
            <body>
                Experience the ultimate convenience of managing your finances
                with Hi-Land Credit Union's Remote Deposit service. No longer
                restricted to visiting a physical branch, our Remote Deposit
                feature brings the bank to you, enabling you to deposit checks
                securely and swiftly from the comfort of your own home or
                anywhere you may be.
            </body>
            <br />
            <body>
                This user-friendly service allows you to deposit checks using
                your mobile device, eliminating the need for time-consuming
                visits to a branch. Simply snap a photo of your check, submit it
                through our secure online portal, and watch as your funds are
                seamlessly deposited into your account. Hi-Land's Remote Deposit
                feature is designed for efficiency, providing a quick and
                hassle-free solution for handling your financial transactions.
            </body>
            <br />
            <body>
                Join the modern era of banking with Hi-Land Credit Union's
                Remote Deposit, empowering you with the flexibility to manage
                your funds on your own terms. Embrace the simplicity of
                depositing checks remotely, saving you time and ensuring your
                banking experience is as convenient as possible.
            </body>
            <br />
            <SectionTitle icon={AtmIcon} children={<h2 id="atm">ATM</h2>} />
            <br />
            <body>
                Access your funds globally with ease through Hi-Land Credit
                Union's affiliation with the CO-OP network, providing you with
                free ATM access worldwide. As a member of the CO-OP network, we
                prioritize your convenience, allowing you to enjoy seamless and
                fee-free transactions at a vast network of ATMs across the
                globe.
            </body>
            <br />
            <body>
                Whether you're in Murray, Utah, or anywhere around the world,
                you can rely on the CO-OP network to provide you with widespread
                access to ATMs, ensuring that you can manage your finances
                without worrying about additional fees. Enjoy the flexibility of
                accessing your funds at various locations, all while benefiting
                from the convenience and cost-effectiveness that the CO-OP
                network brings to your banking experience.
            </body>
            <br />
            <body>
                Hi-Land Credit Union is dedicated to offering you the utmost
                convenience in managing your finances, and our collaboration
                with the CO-OP network is a testament to our commitment to
                providing you with a seamless and global banking experience.
            </body>
            <br />
            <SectionTitle
                icon={MachineIcon}
                children={<h2 id="cm">Card Machine</h2>}
            />
            <br />
            <body>
                Coming Soon!
                <br />
                <br />
                Benefit from Hi-Land Credit Union's in-house card machine,
                delivering the convenience of instant credit and debit card
                printing within minutes. Our advanced card-printing technology
                ensures swift access to new cards on-site, providing a seamless
                and efficient solution for your financial needs. Experience the
                ease of obtaining your cards without delay, enhancing your
                banking experience with Hi-Land Credit Union's innovative
                in-house card printing service.
            </body>
            <br />
        </CopywriteLayout>
    );
}
