import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Input formatting
import { Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

export default function CreditCardForm({ control }) {
    return (
        <Box>
            <Grid
                container
                spacing={{ xs: 1, sm: 1.5, md: 2 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
            >
                <Grid item xs={4} sm={8} md={12}>
                    <Typography fontSize={{ xs: 16, sm: 20 }}>
                        Income Information
                    </Typography>
                </Grid>
                <Grid item xs={4} sm={8} md={12}>
                    <Controller
                        name="card.income.employmentAmount"
                        control={control}
                        defaultValue=""
                        render={({
                            field: { ref, onChange, name, value },
                            fieldState: { invalid, error },
                        }) => (
                            <NumericFormat
                                inputRef={ref}
                                name={name}
                                value={value}
                                onChange={onChange}
                                error={invalid}
                                helperText={error?.message}
                                label="Employment Annual Income"
                                fullWidth
                                thousandSeparator
                                prefix="$"
                                customInput={TextField}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={4} sm={8} md={12}>
                    <Controller
                        name="card.income.otherAmount"
                        control={control}
                        defaultValue=""
                        render={({
                            field: { ref, onChange, name, value },
                            fieldState: { invalid, error },
                        }) => (
                            <NumericFormat
                                inputRef={ref}
                                name={name}
                                value={value}
                                onChange={onChange}
                                error={invalid}
                                helperText={error?.message}
                                label="Other Annual Income (optional)"
                                fullWidth
                                thousandSeparator
                                prefix="$"
                                customInput={TextField}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={4} sm={8} md={12}>
                    <Controller
                        name="card.income.savingsAmount"
                        control={control}
                        defaultValue=""
                        render={({
                            field: { ref, onChange, name, value },
                            fieldState: { invalid, error },
                        }) => (
                            <NumericFormat
                                inputRef={ref}
                                name={name}
                                value={value}
                                onChange={onChange}
                                error={invalid}
                                helperText={error?.message}
                                label="Amount in Savings"
                                fullWidth
                                thousandSeparator
                                prefix="$"
                                customInput={TextField}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={4} sm={8} md={12}>
                    <Typography fontSize={{ xs: 16, sm: 20 }}>
                        Debt Information
                    </Typography>
                </Grid>
                <Grid item xs={4} sm={8} md={12}>
                    <Controller
                        name="card.debt.housingAmount"
                        control={control}
                        defaultValue=""
                        render={({
                            field: { ref, onChange, name, value },
                            fieldState: { invalid, error },
                        }) => (
                            <NumericFormat
                                inputRef={ref}
                                name={name}
                                value={value}
                                onChange={onChange}
                                error={invalid}
                                helperText={error?.message}
                                label="Mortgage/ Rent Monthly Payment"
                                fullWidth
                                thousandSeparator
                                prefix="$"
                                customInput={TextField}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={4} sm={8} md={12}>
                    <Controller
                        name="card.debt.autoAmount"
                        control={control}
                        defaultValue=""
                        render={({
                            field: { ref, onChange, name, value },
                            fieldState: { invalid, error },
                        }) => (
                            <NumericFormat
                                inputRef={ref}
                                name={name}
                                value={value}
                                onChange={onChange}
                                error={invalid}
                                helperText={error?.message}
                                label="Auto Monthly Payment"
                                fullWidth
                                thousandSeparator
                                prefix="$"
                                customInput={TextField}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={4} sm={8} md={12}>
                    <Controller
                        name="card.debt.otherAmount"
                        control={control}
                        defaultValue=""
                        render={({
                            field: { ref, onChange, name, value },
                            fieldState: { invalid, error },
                        }) => (
                            <NumericFormat
                                inputRef={ref}
                                name={name}
                                value={value}
                                onChange={onChange}
                                error={invalid}
                                helperText={error?.message}
                                label="Other Debt Amount"
                                fullWidth
                                thousandSeparator
                                prefix="$"
                                customInput={TextField}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
