import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function createData(name, maximum_loan_terms, apr) {
  return { name, maximum_loan_terms, apr };
}

const rows = [
  createData('Share Secured', '60 Months', '5.99%'),
  createData('Overdraft Line of Credit', 'Open-End', '18.00%'),
  createData('Signature Loan', '12 Months', '8.90%'),
  createData('Signature Loan', '36 Months', '9.90%'),
];

export default function ConsumerLoansTable() {
  return (
    <TableContainer>
      <Table stickyHeader={true} aria-label="table">
        <TableHead>
          <TableRow>
            <TableCell>Loan Type</TableCell>
            <TableCell align="right">Maximum Loan Term</TableCell>
            <TableCell align="right">APR</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0,
                },
              }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.maximum_loan_terms}</TableCell>
              <TableCell align="right">{row.apr}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
