import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
// import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// import Typography from '../../modules/components/Typography';
// import TextField from '@mui/material/TextField';
// import TextField from '../../modules/components/TextField';
import NcuaIcon from '../../assets/ncua.png';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'rgba(255, 255, 255, 0.0)',
    // ...theme.typography.body2,
    ...theme.typography.body1,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    boxShadow: 'none', // toggle for development
    borderRadius: 0,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));

// function Copyright() {
//     return (
//         <React.Fragment>
//             {'© '}
//             <Link color="inherit" href="https://mui.com/">
//                 Hilandcu.com
//             </Link>{' '}
//             {new Date().getFullYear()}
//         </React.Fragment>
//     );
// }

// const LANGUAGES = [
//     {
//         code: 'en-US',
//         name: 'English',
//     },
//     {
//         code: 'fr-FR',
//         name: 'Français',
//     },
// ];

export default function AppFooter() {
    return (
        <Box
            component="footer"
            sx={{
                display: 'flex',
                bgcolor: 'rgb(246, 246, 246, 1.0)',
                // marginTop: 6,
            }}
        >
            <Grid
                container
                spacing={{ xs: 4, sm: 1, md: 1 }}
                columns={{ xs: 4, sm: 12, md: 12 }}
                sx={{
                    padding: {
                        xs: 3,
                        sm: 3,
                    },
                }}
            >
                <Grid item xs={4} sm={4} md={4}>
                    <Item>
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 'bold',
                            }}
                        >
                            Hi-Land Credit Union
                        </Typography>
                        <Typography variant="body2">
                            5366 S Executive Park Dr
                        </Typography>
                        <Typography variant="body2">
                            Murray, Utah 84117
                        </Typography>
                    </Item>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <Item>
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 'bold',
                            }}
                        >
                            Need Help?
                        </Typography>
                        <Typography variant="body2">
                            Give us a call today:
                        </Typography>
                        <Typography variant="body2">(801) 261-8909</Typography>
                    </Item>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <Item>
                        <Box
                            sx={{
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                component="img"
                                src={NcuaIcon}
                                alt="increase priority"
                                sx={{
                                    width: {
                                        xs: 160,
                                        md: 200,
                                    },
                                }}
                            />
                        </Box>
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
}
