import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function createData(name, apr) {
    return { name, apr };
}

const rows = [
    createData('60 Months', '8.48%'),
    createData('72 Months', '8.93%'),
    createData('84 Months', '8.93%'),
    createData('96 Months', '8.93%'),
    createData('120 Months', '8.93%'),
];

export default function RvBoatLoansTable() {
    return (
        <TableContainer>
            <Table stickyHeader={true} aria-label="table">
                <TableHead>
                    <TableRow>
                        <TableCell>Maximum Loan Term(s)</TableCell>
                        <TableCell align="right">APR</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.apr}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
