import React from 'react';
import CopywriteLayout from '../components/layouts/CopywriteLayout';

const SectionTitle = ({ icon, children }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    }}
  >
    {/* <Box
            style={{
                display: 'flex',
                marginRight: '10px',
            }}
        >
            <img src={icon} alt="" width={40} height={40} />
        </Box> */}
    {children}
  </div>
);

export default function LegalPage() {
  return (
    <CopywriteLayout>
      <br />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h1>Hi-Land Credit Union Terms of Use</h1>
      </div>
      <br />
      <SectionTitle
        children={
          <h2 id="policy">Privacy Policy & Notice of Data Collection</h2>
        }
      />
      <br />
      <body>
        Hi-Land Credit Union is committed to making available financial products
        and services that will enable its members to meet their financial needs
        and goals. Protecting personal information and using it in a manner
        consistent with member expectations is a high priority to everyone
        associated with this credit union.
      </body>
      <br />
      <body>
        To ensure that members can rely upon the quality of products and
        services we make available, our credit union stands behind the following
        privacy policy:
      </body>
      <br />
      <body>
        The credit union will collect only the personal information that is
        necessary to conduct our business. That means just what is necessary to
        provide competitive financial products and services and no more. We
        collect this information about members from applications and other
        forms, information about member transactions with us, our affiliates or
        others, and information we receive from consumer reporting agencies.
      </body>
      <br />
      <body>
        The credit union discloses nonpublic personal information about its
        members and former members to companies that offer financial products
        and services, including insurance products and securities brokerage
        companies. The credit union also discloses nonpublic information to
        other nonaffiliated third parties as permitted by law.
      </body>
      <br />
      <body>
        The credit union will partner only with businesses that follow strict
        confidentiality requirements. The businesses we select will offer
        products designed to enhance our members' economic well-being. Under no
        circumstances will we authorize those firms to charge a member's account
        without receiving their express consent, and we will not sell member
        information to telemarketing firms.
      </body>
      <br />
      <body>
        Members that prefer that we not disclose nonpublic personal information
        about them to nonaffiliated third parties may opt out of these
        disclosures, and direct us not to make those disclosures to
        nonaffiliated third parties. To opt out, members may call us at (801)
        261-8909. Members may also notify us in writing by mailing a request to
        5366 S Executive Park Dr., Murray, Ut 84117, or by electronic mail at:
        hiland@hilandcu.com.
      </body>
      <br />
      <body>
        Hi-Land Credit Union will protect the personal information of its
        members. This credit union will maintain strong security controls to
        ensure that member information in our files and computers is protected.
        We limit employee access to confidential member financial information to
        those employees with a business reason for knowing such information.
        Additionally, we maintain physical, electronic and procedural safeguards
        that comply with federal regulations.
      </body>
      <br />
      <body>Questions? Call us today at (801) 261-8909</body>
      <br />
      <SectionTitle
        children={<h2 id="eligibility">Membership Eligibility</h2>}
      />
      <br />
      <body>
        To become a member of Hi-Land Credit Union you must meet <b>one</b> of
        these eligibility requirements:
      </body>
      <br />
      <li>Reside in Salt Lake County</li>
      <li>
        You are related to a current Hi-Land Credit Union member (parent,
        spouse, child or sibling)
      </li>
      <li>
        You are employed by Cream O' Weber, Dairy Farmers of America or Tri-City
        Medical.
      </li>
      <br />
      <body>
        If you're eligible, it's easy to join Hi-Land Credit Union. Simply call
        us to schedule an appointment to open an account. A Share account
        requires a minimum deposit of $25. This represents your "ownership
        share" in the credit union and affords you all the benefits that come
        with it!
      </body>
      <br />
      <body>Questions? Call us today at (801) 261-8909</body>
      {/* <SectionTitle children={<h2 id="disclosures">Fees & Disclosures</h2>} />
      <br />
      <body>
        The following pages contain important disclosure information for Hi-Land
        Credit Union members:
      </body>
      <br />
      <li>Terms & Conditions of your Deposit Account</li>
      <li>Electronic Transfers Your Rights & Responsibilities</li>
      <li>Funds Availability Disclosure</li>
      <li>Truth-in-Savings Disclosure</li>
      <li>CUNA Mutual Insurance Society Group Policy of Life Insurance</li>
      <br /> */}
      <br />
      <SectionTitle children={<h2 id="directory">Staff Directory</h2>} />
      <br />
      <body>
        Please contact us or any of the following credit union representatives
        for credit union information.
      </body>
      <br />
      <body>
        <b>Contact Email:</b> hiland@hilandcu.com
      </body>
      <br />
      <body>
        <b>
          <i>Misty Miller, President</i>
        </b>
        <br />
        National Mortgage Licese # 1893702
      </body>
      <br />
      <body>
        <b>
          <i>Tim Wilde, Vice-President</i>
        </b>
        <br />
        National Mortgage Licese # 789558
      </body>
      <br />
    </CopywriteLayout>
  );
}
