import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';

export default function SubmitForm({ applicantWatch, getValues }) {
    // Axios instance
    const api = axios;
    const navigate = useNavigate();
    const [buttonLoading, setButtonLoading] = React.useState(false);

    const handleSubmit = async () => {
        const formPayload = getValues();
        setButtonLoading(true);
        try {
            const result = await api.post(
                'api/forms/application/submit',
                formPayload
                // { withCredentials: true }
            );
            if (result.status === 200) {
                setButtonLoading(false);
                navigate('/applications/completed');
                // TODO: Log status
            }
        } catch (error) {
            setButtonLoading(false);
            navigate('/applications/completed?error');
            // TODO: Log status
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                margin: '0 auto',
                maxWidth: '500px',
            }}
        >
            <Typography variant="h5" sx={{ marginBottom: 3 }}>
                {applicantWatch.personal.firstName ?? ''}, please review your
                contact information below and submit. We'll be in touch shortly!
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    // alignContent: 'center',
                    justifyContent: 'center',
                    // width: '100%',
                    marginBottom: 3,
                }}
            >
                <Typography width="100%" variant="h5">
                    {applicantWatch.personal.firstName ?? ''}{' '}
                    {applicantWatch.personal.lastName ?? ''}
                    <br />
                    {applicantWatch.contact.phone ?? ''}
                    <br />
                    {applicantWatch.contact.email ?? ''}
                </Typography>
            </Box>

            <LoadingButton
                onClick={handleSubmit}
                variant="contained"
                color="secondary"
                loading={buttonLoading}
            >
                Submit
            </LoadingButton>
        </Box>
    );
}
