import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Container from '@mui/material/Container';

const menuItems = {
    fontSize: 16,
    // color: 'common.black',
    // ml: 3,
    // mr: '10%',
    // borderRadius: 5,
    textTransform: 'none',
    flexShrink: 0,
    '&:hover': {
        textDecoration: 'underline',
        backgroundColor: 'common.white',
        // color: 'common.black',
        boxShadow: 'none',
    },
    '&:active': {
        backgroundColor: 'none',
        color: 'none',
        boxShadow: 'none',
        backgroundColor: 'none',
    },
};

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#ffffff',
        color: 'rgba(0, 0, 0, 0.87)',
        // maxWidth: '100%',
        width: '5000px',
        fontSize: theme.typography.pxToRem(12),
        border: '5px solid #ffffff',
        borderRadius: 0,
        marginLeft: -0.25,
    },
}));

export default function CustomizedTooltips({ children }) {
    const [screenSize, setScreenSize] = React.useState(getCurrentDimension());

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight,
        };
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension());
        };
        window.addEventListener('resize', updateDimension);

        return () => {
            window.removeEventListener('resize', updateDimension);
        };
    }, [screenSize]);

    return (
        <>
            {children.map((child, index) => {
                return (
                    <HtmlTooltip
                        key={index}
                        title={
                            <React.Fragment>
                                <Container
                                    maxWidth="lg"
                                    sx={{
                                        width: '100%',
                                    }}
                                >
                                    {child}
                                </Container>
                            </React.Fragment>
                        }
                        placement="bottom"
                        sx={{
                            '& .MuiTooltip-tooltip': {
                                maxWidth: `${screenSize.width}px`,
                            },
                        }}
                    >
                        <Button
                            key={index}
                            disableRipple
                            color="inherit"
                            sx={menuItems}
                            href={child?.props?.href}
                        >
                            {child?.props?.title}
                        </Button>
                    </HtmlTooltip>
                );
            })}
        </>
    );
}
