import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CopywriteLayout from '../components/layouts/CopywriteLayout';

import CheckIcon from '../assets/icons/banking/check.png';
import VaultIcon from '../assets/icons/banking/vault.png';
import DollarIcon from '../assets/icons/banking/dollar.png';
import MagnifierIcon from '../assets/icons/banking/magnifier.png';
import CertificateIcon from '../assets/icons/banking/certificate.png';
import CardIcon from '../assets/icons/banking/card.png';

import ExternalLink from '../components/modals/ExternalLink';

const SectionTitle = ({ icon, children }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    }}
  >
    <Box
      style={{
        display: 'flex',
        marginRight: '10px',
      }}
    >
      <img src={icon} alt="" width={40} height={40} />
    </Box>
    {children}
  </div>
);

export default function AccountsPage() {
  // const accountsFile = require('../copywriting/AccountsCopy.md');

  return (
    <CopywriteLayout>
      <br />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h1>Accounts at Hi-Land Credit Union</h1>
      </div>
      <br />
      <SectionTitle
        icon={CheckIcon}
        children={<h2 id="checking">Checking Accounts</h2>}
      />
      <br />
      <body>
        Embark on an enriching financial journey at Hi-Land Credit Union as you
        explore our extensive range of checking accounts, each designed to cater
        to your unique needs and preferences. Revel in the exclusive advantages,
        including the absence of annual fees, competitive dividends generously
        offered on balances surpassing $500, and the added convenience of a VISA
        Debit Card, granting you effortless access to your funds whenever you
        need.
      </body>
      <br />
      <body>
        Enjoy peace of mind as we prioritize the security of your financial
        assets. At Hi-Land Credit Union, your accounts are meticulously
        safeguarded by the National Credit Union Administration (NCUA),
        providing an extra layer of assurance with protection guaranteed up to
        at least $250,000*. Trust us with the responsibility of managing your
        finances as we endeavor to elevate your experience through our share
        accounts.
      </body>
      <br />
      <body>
        Our commitment extends beyond mere financial transactions; it
        encompasses your overall well-being. Experience tranquility in your
        financial journey with our dedicated efforts, including continuous 24/7
        fraud monitoring and a robust suite of security measures. At Hi-Land
        Credit Union, we don't just provide financial services; we curate an
        environment that fosters confidence, security, and optimal financial
        management, ensuring your journey with us is nothing short of
        exceptional.
      </body>
      <br />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button href="/rates" color="primary">
          Explore our rates
        </Button>
      </div>
      <br />
      <SectionTitle
        icon={VaultIcon}
        children={<h2 id="savings">Savings Accounts</h2>}
      />
      <br />
      <body>
        Hi-Land Credit Union boasts a range of savings accounts to suit your
        preferences. Our Share Savings accounts not only offer competitive
        dividends but also the chance to earn even more with larger minimum
        balances. Enjoy easy access to your funds while benefiting from high
        dividends.
      </body>
      <br />
      <body>
        Rest assured that your hard-earned money is in safe hands with us.
        Hi-Land Credit Union is federally insured by NCUA, guaranteeing the
        safety of your accounts up to at least $250,000*. We provide
        round-the-clock fraud monitoring, ensuring the security of your account.
        Join us in the confidence that your financial well-being is our top
        priority.
      </body>
      <br />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button href="/rates" color="primary">
          Explore our rates
        </Button>
      </div>
      <br />
      <SectionTitle
        icon={DollarIcon}
        children={<h2 id="market">Money Market Accounts</h2>}
      />
      <br />
      <body>
        Discover the versatility of money market accounts at Hi-Land Credit
        Union. Our Money Market accounts cater to your preferences, offering
        competitive dividends and the opportunity for even greater returns with
        larger minimum balances. Enjoy seamless access to your funds while
        reaping the benefits of high dividends.
      </body>
      <br />
      <body>
        Rest easy knowing that your funds are securely managed by Hi-Land Credit
        Union. We are federally insured by NCUA, ensuring the safety of your
        accounts up to at least $250,000*. With continuous 24/7 fraud
        monitoring, your account's security is our constant priority. Trust us
        to prioritize your financial well-being and join Hi-Land Credit Union
        with confidence.
      </body>
      <br />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button href="/rates" color="primary">
          Explore our rates
        </Button>
      </div>
      <br />
      <SectionTitle icon={MagnifierIcon} children={<h2 id="ira">IRAs</h2>} />
      <br />
      <body>
        Explore the diverse options of Individual Retirement Accounts (IRAs) at
        Hi-Land Credit Union. Our IRA accounts are tailored to your preferences,
        delivering competitive dividends and the opportunity for increased
        returns with larger minimum balances. Experience convenient access to
        your funds while enjoying the advantages of high dividends.
      </body>
      <br />
      <body>
        Rest easy knowing your valuable assets are secure with us. Hi-Land
        Credit Union is federally insured by NCUA, safeguarding your accounts
        with a guarantee of at least $250,000*. With continuous 24/7 fraud
        monitoring, we prioritize the security of your IRA account. Choose
        Hi-Land Credit Union with confidence, trusting that your financial
        well-being is our utmost priority.
      </body>
      <br />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button href="/rates" color="primary">
          Explore our rates
        </Button>
      </div>
      <br />
      <SectionTitle
        icon={CertificateIcon}
        children={<h2 id="cd">Certificates Of Deposit</h2>}
      />
      <br />
      <body>
        Discover the variety of investment opportunities at Hi-Land Credit Union
        with our Certificates of Deposit (CDs). Unlike our Share Savings
        accounts, CDs offer not only competitive dividends but also the
        potential for increased returns with larger minimum balances. Revel in
        the ease of accessing your funds while enjoying the perks of high
        dividends.
      </body>
      <br />
      <body>
        Rest easy, knowing that your valuable funds are securely managed by
        Hi-Land Credit Union. Our commitment to your financial safety is
        solidified by our federal insurance through NCUA, ensuring the
        protection of your accounts up to at least $250,000*. Additionally, our
        continuous 24/7 fraud monitoring guarantees the utmost security for your
        CD account. Join us with the confidence that your financial well-being
        remains our paramount concern.
      </body>
      <br />
      <body>
        To further enhance your savings, we provide enticing CD options with
        high rates for terms ranging from 12 to 24 months. These CDs not only
        offer high dividends but also secure your funds for a brief yet
        rewarding period. Invest with us, and let Hi-Land Credit Union optimize
        your returns while prioritizing your financial success.
      </body>
      <br />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button href="/rates" color="primary">
          Explore our rates
        </Button>
      </div>
      <br />
      <SectionTitle
        icon={CardIcon}
        children={<h2 id="debitcards">Debit Cards</h2>}
      />
      <br />
      <body>
        Receive a complimentary debit card with qualifying accounts and enjoy
        the upcoming convenience of Hi-Land Credit Union's in-house card
        machine. Our advanced technology ensures swift access to new cards
        on-site, providing a seamless solution for your financial needs.
        Experience the ease of obtaining your cards without delay, enhancing
        your banking experience with Hi-Land Credit Union's innovative in-house
        card printing service.
      </body>
      <br />
      <br />
      <br />
      <br />
      <br />
      <body style={{ fontSize: '0.5rem' }}>
        *The National Credit Union Administration (NCUA) insures your savings up
        $250,000. Deposit insurance is determined by ownership and not by
        account type. For more information, please visit:{''}
        <ExternalLink href="https://ncua.gov" title="ncua.gov" />
      </body>
    </CopywriteLayout>
  );
}
