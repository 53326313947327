import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import MainAppBar from '../nav/MainAppBar';
import AppFooter from '../nav/AppFooter';

export default function CopywriteLayout({ children }) {
    const scrolledRef = useRef(false);
    const { hash } = useLocation();

    useEffect(() => {
        if (hash && !scrolledRef.current) {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                element.style.scrollMargin = '20px';
                element.scrollIntoView({ behavior: 'smooth' });
                scrolledRef.current = false;
            }
        }
    });
    return (
        <Container disableGutters>
            <MainAppBar />
            <Box sx={{ paddingX: { xs: 3, sm: 6, md: 9, lg: 12 } }}>
                {children}
            </Box>
            <AppFooter />
        </Container>
    );
}
