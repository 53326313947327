import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { useForm } from 'react-hook-form';

import FormStepper from './loans/FormStepper';

// Forms
import ApplicationType from './loans/ApplicationType';
import AutoLoanForm from './auto/AutoForm';
import CreditCardForm from './creditcard/CreditCardForm';
// import HomeLoanForm from './home/HomeForm';
import ApplicantForm from './applicant/ApplicantForm';
import CoApplicantForm from './applicant/CoApplicantForm';
import SubmitForm from './loans/SubmitForm';

// Form Validation
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const labels = {
  auto: {
    label: 'Auto Loan',
    value: 'auto',
  },
  card: {
    label: 'Credit Card Application',
    value: 'card',
  },
  home: {
    label: 'Home Loan',
    value: 'home',
  },
};

const schema = yup.object().shape({
  application: yup.object().shape({
    type: yup.string().required().oneOf(Object.keys(labels)),
  }),
  card: yup.object().shape({
    income: yup.object().shape({
      employmentAmount: yup.string().required('Please make a selection'),
      otherAmount: yup.string(),
      savingsAmount: yup.string().required('This field is required'),
    }),
    debt: yup.object().shape({
      housingAmount: yup.string().required('This field is required'),
      autoAmount: yup.string().required('This field is required'),
      otherAmount: yup.string().required('This field is required'),
    }),
  }),
  home: yup.object().shape({
    amount: yup.string().required('This field is required'),
    type: yup.string().required('Please make a selection'),
    property: yup.object().shape({
      type: yup.string().required('Please make a selection'),
      street: yup.string().required('This field is required'),
      street2: yup.string(),
      city: yup.string().required('This field is required'),
      state: yup.string().required('This field is required'),
      zip: yup.string().required('This field is required'),
      country: yup.string().required('This field is required'),
    }),
  }),
  auto: yup.object().shape({
    amount: yup.string().required('This field is required'),
    vehicle: yup.object().shape({
      type: yup.string().required('Please make a selection'),
      condition: yup.string().required('Please make a selection'),
      make: yup.string().required('This field is required'),
      model: yup.string().required('This field is required'),
      year: yup.string().required('This field is required'),
      mileage: yup.string().required('This field is required'),
    }),
  }),
  applicant: yup.object().shape({
    personal: yup.object().shape({
      marriageStatus: yup.string().required('Please make a selection'),
      firstName: yup.string().required('This field is required'),
      middleName: yup.string(),
      lastName: yup.string().required('This field is required'),
    }),
    contact: yup.object().shape({
      phone: yup.string().required('This field is required'),
      email: yup.string().required('This field is required'),
    }),
    address: yup.object().shape({
      ownership: yup.string().required('Please make a selection'),
      residenceYears: yup
        .string()
        .required('This field is required')
        .max(2, 'Please double check your entry'),
      street: yup.string().required('This field is required'),
      street2: yup.string(),
      city: yup.string().required('This field is required'),
      state: yup.string().required('This field is required'),
      zip: yup.string().required('This field is required'),
      country: yup.string().required('This field is required'),
    }),
  }),
  coApplicant: yup.object().shape({
    applicant: yup.string().required('Please make a selection'),
    personal: yup.object().shape({
      marriageStatus: yup.string().required('Please make a selection'),
      firstName: yup.string().required('This field is required'),
      middleName: yup.string(),
      lastName: yup.string().required('This field is required'),
    }),
    contact: yup.object().shape({
      phone: yup.string().required('This field is required'),
      email: yup.string().required('This field is required'),
    }),
    address: yup.object().shape({
      ownership: yup.string().required('Please make a selection'),
      residenceYears: yup
        .string()
        .required('This field is required')
        .max(2, 'Please double check your entry'),
      street: yup.string().required('This field is required'),
      street2: yup.string(),
      city: yup.string().required('This field is required'),
      state: yup.string().required('This field is required'),
      zip: yup.string().required('This field is required'),
      country: yup.string().required('This field is required'),
    }),
  }),
});

export default function FormsPage() {
  const {
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    control,
    watch,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Stepper Controls
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scrollTo(0, 0);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scrollTo(0, 0);
  };

  // Form Controls
  const watchApplicationType = watch('application.type');
  const applicantWatch = watch('applicant');
  const coApplicantWatch = watch('coApplicant.applicant');

  const pageLabels = {
    0: 'application',
    1: watchApplicationType,
    2: 'applicant',
    3: 'coApplicant',
  };

  const onSubmitHandler = (data) => {
    handleNext();
  };

  const onErrorHandler = (data) => {
    console.log(data);
    if (
      !(pageLabels[activeStep] in data) ||
      (activeStep === 3 && coApplicantWatch === 'false')
    ) {
      clearErrors();
      handleNext();
      return;
    }
  };

  const steps = [
    {
      step: 0,
      label: 'Select Application',
      description: 'Need assistance? Give us a call today: (801) 261-8909',
      content: (
        <Box>
          <ApplicationType
            control={control}
            setValue={setValue}
            watchApplicationType={watchApplicationType}
            reset={reset}
            errors={errors}
          />
        </Box>
      ),
    },
    {
      step: 1,
      label: `${
        watchApplicationType ? labels[watchApplicationType].label : ''
      }`,
      description: 'Please enter details below',
      content: (
        <Box>
          {watchApplicationType === 'auto' ? (
            <AutoLoanForm control={control} />
          ) : (
            <>
              <Box display="flex" justifyContent="center" marginBottom="1.5rem">
                <body>
                  Please review our{' '}
                  <a
                    href="/static/documents/credit_card_agreement_and_disclosures.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Credit Card Agreement and Disclosures
                  </a>{' '}
                  before applying
                </body>
              </Box>
              <CreditCardForm control={control} />
            </>
            // <HomeLoanForm control={control} />
          )}
        </Box>
      ),
    },
    {
      step: 3,
      label: 'Your Information',
      description: 'Please enter your personal information',
      content: (
        <Box>
          <ApplicantForm control={control} />
        </Box>
      ),
    },
    {
      step: 4,
      label: 'Co-Applicant',
      description: 'Please enter co-applicant details (if applicable)',
      content: (
        <Box>
          <CoApplicantForm
            control={control}
            coApplicantWatch={coApplicantWatch}
          />
        </Box>
      ),
    },
    {
      step: 5,
      label: 'Review & Submit',
      description: '',
      content: (
        <SubmitForm applicantWatch={applicantWatch} getValues={getValues} />
      ),
    },
  ];

  return (
    <Box
      id="form"
      component="form"
      onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}
      sx={{
        paddingX: {
          xs: 0,
          sm: 6,
          md: 9,
        },
        marginY: {
          xs: 3,
          lg: 4,
        },
      }}
    >
      <FormStepper
        steps={steps}
        activeStep={activeStep}
        handleBack={handleBack}
      />
    </Box>
  );
}
