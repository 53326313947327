import * as React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

import Link from '@mui/material/Link';
import Typography from '../../modules/components/Typography';
import AppForm from '../../modules/views/AppForm';
import { email, required } from '../../modules/form/validation';
import RFTextField from '../../modules/form/RFTextField';
import FormButton from '../../modules/form/FormButton';
import FormFeedback from '../../modules/form/FormFeedback';
import withRoot from '../../modules/withRoot';

export default function SignIn() {
  const [sent, setSent] = React.useState(false);

  const validate = (values) => {
    const errors = required(['email', 'password'], values);

    if (!errors.email) {
      const emailError = email(values.email);
      if (emailError) {
        errors.email = emailError;
      }
    }

    return errors;
  };

  const handleSubmit = () => {
    setSent(true);
  };

  return (
    <Box>
      <Box>
        <Typography
          variant="h4"
          gutterBottom
          marked="center"
          align="center"
          style={{
            fontSize: 32,
            color: 'rgb(40, 40, 42, 0.9)',
          }}
        >
          Welcome
        </Typography>
        {/* <Typography variant="body2" align="center">
                        {'Not a member yet? '}
                        <Link
                            href="/premium-themes/onepirate/sign-up/"
                            align="center"
                            underline="always"
                        >
                            Sign Up here
                        </Link>
                    </Typography> */}
      </Box>
      {/* <Box component="form" onSubmit={handleSubmit} validate={validate}> */}
      <Box
        component="form"
        method="post"
        action="https://www.mobicint.net/hil/login"
        rel="noopener"
        onSubmit={handleSubmit}
      >
        <meta name="referrer" content="origin" />
        <TextField
          variant="standard"
          margin="dense"
          required
          fullWidth
          id="user"
          name="user"
          type="text"
          label="Username"
          autoComplete="username"
          autoFocus
        />
        <TextField
          variant="standard"
          margin="dense"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Save username"
          sx={
            {
              // TODO:  Button border radius - make square
            }
          }
        />
        <Button
          type="submit"
          name="submit"
          value="Submit"
          fullWidth
          variant="contained"
          color="secondary"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </Button>
        <Typography align="center">
          <Link
            variant="body2"
            underline="always"
            href="https://mobicint.net/hil/forgotPassword"
          >
            Forgot password?
          </Link>
        </Typography>
      </Box>
      {/* <Form
                    onSubmit={handleSubmit}
                    subscription={{ submitting: true }}
                    validate={validate}
                >
                    {({ handleSubmit: handleSubmit2, submitting }) => (
                        <Box
                            component="form"
                            onSubmit={handleSubmit2}
                            noValidate
                            sx={{ mt: 0 }}
                        >
                            <Field
                                autoComplete="email"
                                autoFocus
                                component={RFTextField}
                                disabled={submitting || sent}
                                fullWidth
                                // label="Email"
                                // margin="normal"
                                name="email"
                                required
                                size="medium"
                            />
                            <Field
                                fullWidth
                                size="large"
                                component={RFTextField}
                                disabled={submitting || sent}
                                required
                                name="password"
                                autoComplete="current-password"
                                label="Password"
                                type="password"
                                margin="normal"
                            />
                            <FormSpy subscription={{ submitError: true }}>
                                {({ submitError }) =>
                                    submitError ? (
                                        <FormFeedback error sx={{ mt: 2 }}>
                                            {submitError}
                                        </FormFeedback>
                                    ) : null
                                }
                            </FormSpy>
                            <FormButton
                                sx={{ mt: 3, mb: 2 }}
                                disabled={submitting || sent}
                                size="large"
                                color="secondary"
                                fullWidth
                            >
                                {submitting || sent
                                    ? 'In progress…'
                                    : 'Sign In'}
                            </FormButton>
                        </Box>
                    )}
                </Form>
                <Typography align="center">
                    <Link
                        underline="always"
                        href="https://mobicint.net/hil/forgotPassword"
                    >
                        Forgot password?
                    </Link>
                </Typography> */}
    </Box>
  );
}
