import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import ProductCard from '../cards/ProductCard';

import SavingIcon from '../../assets/icons/money/saving.png';
import PaymentIcon from '../../assets/icons/money/payment.png';
import MonitorIcon from '../../assets/icons/money/monitor.png';
import HandshakeIcon from '../../assets/icons/money/handshake.png';

const BasicLayoutRoot = styled('section')(({ theme }) => ({
    color: theme.palette.common.white,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    // [theme.breakpoints.up('xs')]: {
    //     height: '80vh',
    //     minHeight: 400,
    //     maxHeight: 1300,
    //     maxHeight: 450,
    // },
    // [theme.breakpoints.up('sm')]: {
    //     height: '80vh',
    //     minHeight: 400,
    //     maxHeight: 1300,
    //     maxHeight: 500,
    // },
}));

export default function CardContainer() {
    return (
        <Box sx={{ flexGrow: 1, marginY: { xs: 1, sm: -1, md: 2 } }}>
            <BasicLayoutRoot
                style={{
                    // backgroundImage: `url(${SplashImage})`,
                    // backgroundColor: '#737373', // Average color of the background image.
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover', // contain or cover
                    backgroundPosition: 'center',
                }}
            >
                <Grid
                    container
                    spacing={{ xs: 4, md: 4 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                    sx={{
                        padding: {
                            xs: 4,
                            sm: 6,
                            md: 3,
                        },
                    }}
                >
                    <Grid item xs={4} sm={4} md={3}>
                        <ProductCard
                            title="Competitive Loans"
                            label="Low rates and quick turn around times"
                            icon={SavingIcon}
                            buttonLabel="Get Started"
                            href="/loans"
                            // iconOffset="30px"
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={3}>
                        <ProductCard
                            title="Free Checking Accounts"
                            label="No annual fees and exciting member benefits"
                            icon={HandshakeIcon}
                            buttonLabel="Learn More"
                            href="/accounts"
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={3}>
                        <ProductCard
                            title="Online Banking"
                            label="Intuitive and easy to use, bank from anywhere"
                            icon={MonitorIcon}
                            buttonLabel="Learn More"
                            href="/services"
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={3}>
                        <ProductCard
                            title="Theft Monitoring 24/ 7"
                            label="Our team works around the clock to best serve you"
                            icon={PaymentIcon}
                            buttonLabel="Learn More"
                            href="/resources"
                        />
                    </Grid>
                </Grid>
            </BasicLayoutRoot>
        </Box>
    );
}
