import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import LinkCard from '../../cards/LinkCard';

import CheckIcon from '../../../assets/icons/banking/check.png';
import VaultIcon from '../../../assets/icons/banking/vault.png';
import DollarIcon from '../../../assets/icons/banking/dollar.png';
import MagnifierIcon from '../../../assets/icons/banking/magnifier.png';
import CertificateIcon from '../../../assets/icons/banking/certificate.png';
import CardIcon from '../../../assets/icons/banking/card.png';

import MenuImage from '../../../assets/stock_photos/banker.png';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  ...theme.typography.body1,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  boxShadow: 'none', // toggle for development
  borderRadius: 0,
}));

const ImageItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  ...theme.typography.body1,
  // padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  boxShadow: 'none', // toggle for development
  borderRadius: 0,
}));

const menuItems = {
  fontSize: 16,
  color: 'common.white',
  // ml: 3,
  // mr: '10%',
  // borderRadius: 5,
  textTransform: 'none',
  flexShrink: 0,
  '&:hover': {
    textDecoration: 'underline',
    // backgroundColor: 'common.white',
    // color: 'common.black',
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: 'none',
    color: 'none',
    boxShadow: 'none',
    backgroundColor: 'none',
  },
};

const style = {
  '& .MuiDialog-scrollPaper': {
    // alignItems: 'baseline',
    marginTop: 10,
    alignItems: 'flex-start',
  },
  '& .MuiDialog-paper': {
    //     // marginTop: '-20%',
    //     // alignItems: 'center',
    //     bgcolor: 'background.paper',
    //     color: 'common.white',
    borderRadius: 0,
    boxShadow: 24, // set to zero
    //     p: 3,
    overflow: 'hidden',
  },
  '& .MuiDialog-paperScrollBody': {
    verticalAlign: 'top',
  },
};

export default function MaxWidthDialog() {
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('lg');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 3, minHeight: '450px' }}>
      <Grid
        container
        spacing={0}
        columns={12}
        // sx={{
        //     padding: {
        //         xs: 4,
        //         sm: 6,
        //         md: 3,
        //     },
        // }}

        // sx={{ marginTop: '7%' }}
      >
        {/* CONTAINER 1 */}
        <Grid item>
          <Box
            sx={{
              // width: '100%',
              // width: 275,
              // height: 275,
              // backgroundColor: 'rgba(255, 255, 255, 0.9)',
              // padding: 3,
              // borderRadius: '15px',
              boxShadow:
                '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
            }}
          >
            <ImageItem
              style={{
                backgroundImage: `url(${MenuImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover', // contain or cover
                backgroundPosition: 'center',
                height: 375,
                width: 275,
                // overflow: 'hidden',
              }}
            >
              <ImageItem
                style={{
                  height: '100%',
                  width: '100%',
                  backgroundColor: 'rgba(125, 125, 125, 0.5)',
                  padding: '20px',
                  // overflow: 'hidden',
                }}
              >
                <Typography
                  marginTop="10px"
                  variant="h4"
                  color="white"
                  // sx={{
                  //     typography: {
                  //         lg: 'h2',
                  //         md: 'h3',
                  //         sm: 'h4',
                  //         xs: 'h4',
                  //     },
                  // }}
                  //     sx={{
                  //         overflow: 'hidden',
                  //         minWidth: '0',
                  //     }}
                >
                  <i>Free Checking Accounts</i>
                </Typography>
                <Typography
                  marginTop="3%"
                  color="white"
                  fontSize={14}
                  padding={1}
                >
                  No annual fees and exciting member benefits
                </Typography>
                <Button
                  href="/accounts"
                  variant="contained"
                  sx={{
                    marginTop: '25%',
                    marginLeft: '3%',
                    minWidth: '0',
                    backgroundColor: '#056E8B',
                    '&:hover': {
                      backgroundColor: '#07a8d4',
                    },
                  }}
                >
                  Learn More
                </Button>
                {/* <img
                                    src={ConsultantImage}
                                    alt="consultant"
                                    width={250}
                                    loading="lazy"
                                    style={{ opacity: 0.5 }}
                                /> */}
                {/* <Box
                                    style={{
                                        backgroundImage: `url(${ConsultantImage})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'contain',
                                        height: 375,
                                        // width: 375,
                                        opacity: 0.5,
                                    }}
                                ></Box> */}
                {/* <img
                                    src={ConsultantImage}
                                    alt="consultant"
                                    width={250}
                                    loading="lazy"
                                    style={{ opacity: 0.5 }}
                                /> */}
              </ImageItem>
            </ImageItem>
          </Box>
        </Grid>
        {/* CONTAINER 2 - width (xs) dependent on container 1 */}
        <Grid item xs>
          <Box
            sx={{
              width: '100%',
              height: 375,
              // backgroundColor: 'rgba(245, 245, 245, 0.9)',
              paddingX: 4.5,
              paddingY: 0,
              // borderRadius: '15px',
              // boxShadow:
              //     '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
            }}
          >
            <Grid
              container
              spacing={{ xs: 4, md: 4 }}
              columns={{ xs: 12 }}
              // sx={{
              //     padding: {
              //         xs: 4,
              //         sm: 6,
              //         md: 3,
              //     },
              // }}

              // sx={{ marginTop: '7%' }}
            >
              <Grid item xs={6}>
                <LinkCard
                  title="Checking Accounts"
                  label="Free accounts, no annual fees"
                  icon={CheckIcon}
                  href="/accounts"
                />
              </Grid>
              <Grid item xs={6}>
                <LinkCard
                  title="Savings Accounts"
                  label="Easy access, high dividend accounts"
                  icon={VaultIcon}
                  href="/accounts#savings"
                />
              </Grid>
              <Grid item xs={6}>
                <LinkCard
                  title="Money Market Account"
                  label="Convenient, Interest-bearing accounts"
                  icon={DollarIcon}
                  href="/accounts#market"
                />
              </Grid>
              <Grid item xs={6}>
                <LinkCard
                  title="IRAs"
                  label="Tax-advantaged retirement accounts"
                  icon={MagnifierIcon}
                  href="/accounts#ira"
                />
              </Grid>
              <Grid item xs={6}>
                <LinkCard
                  title="Certificates of Deposit"
                  label="Fixed-rate, fixed-term accounts"
                  icon={CertificateIcon}
                  href="/accounts#cd"
                />
              </Grid>
              <Grid item xs={6}>
                <LinkCard
                  title="Debit Cards"
                  label="Convenient, secure access to funds"
                  icon={CardIcon}
                  href="/accounts#debitcards"
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
