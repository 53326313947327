import React from 'react';
import Box from '@mui/material/Box';
import CopywriteLayout from '../components/layouts/CopywriteLayout';

import ShieldIcon from '../assets/icons/banking/shield.png';
import GlobalIcon from '../assets/icons/banking/global.png';
import ExternalLink from '../components/modals/ExternalLink';

const SectionTitle = ({ icon, children }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    }}
  >
    <Box
      style={{
        display: 'flex',
        marginRight: '10px',
      }}
    >
      <img src={icon} alt="" width={40} height={40} />
    </Box>
    {children}
  </div>
);

export default function SecurityCenterPage() {
  return (
    <CopywriteLayout>
      <br />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h1>Hi-Land Credit Union Security Center</h1>
      </div>
      <br />
      <body>
        Beware of common online scams like identity theft, phishing, and fake
        websites. Protect yourself by staying informed about the latest fraud
        methods, securing personal information, and maintaining up-to-date
        antivirus and spyware programs. Explore the Hi-Land Credit Union
        Security Center for insights on combating identity theft, email scams,
        computer viruses, and more. Stay informed and safeguard against evolving
        threats.
      </body>
      <br />
      <body>Call us today at (801) 261-8909</body>

      <br />
      <SectionTitle
        icon={ShieldIcon}
        children={<h2 id="fraud">Fraud Prevention</h2>}
      />
      <br />
      <body>
        Each year, countless consumers fall victim to identity theft, leading to
        millions of dollars in fraudulent credit card charges and extensive
        hours dedicated to repairing damaged credit files. To safeguard your
        identity and preserve your good credit, adhere to these straightforward
        do's and don'ts.
      </body>
      <br />
      <body>
        A great way to protect yourself from identity theft is to make sure your
        contact information is up-to-date with us. If you move or change your
        phone number, please let us know so we can update your account and
        contact you if we suspect fraudulent activity.
      </body>
      <br />
      <li>
        <b>DON'T</b> give out personal information over the phone
      </li>
      <br />
      <li>
        <b>DON'T</b> respond to e-mails asking you to verify account numbers,
        credit card numbers or PINs
      </li>
      <br />
      <li>
        <b>DON'T</b> respond to correspondence requesting your account number in
        order to claim a cash prize such as lottery winnings
      </li>
      <br />
      <li>
        <b>DO</b> protect your personal information by shredding old account
        statements, credit card offers, or any other document that contains
        personal information
      </li>
      <br />
      <li>
        <b>DO</b> monitor your credit file by reviewing your credit report
        through <i>AnnualCreditReport.com</i>
      </li>
      <br />
      <li>
        <b>DO</b> report any suspicious correspondence, phone calls or e-mails
        to your credit union.
      </li>
      <br />
      <SectionTitle
        icon={GlobalIcon}
        children={<h2 id="personal">Fraud Resources</h2>}
      />
      <br />
      <body>
        If you suspect you've fallen victim to account fraud or identity theft,
        take immediate action to minimize potential damage to your personal
        accounts and credit rating. In the event of suspected fraud related to
        your credit union accounts, promptly reach out to us. We are committed
        to swiftly resolving your claim, ensuring minimal inconvenience to you.
      </body>
      <br />
      <body>
        If you believe you are a target of identity theft, it's advisable to
        reach out to the following agencies:
      </body>
      <br />
      <li>
        Internet Crime Complaint Center <br />
        <p style={{ marginLeft: 15 }}>
          <ExternalLink href="https://www.ic3.gov" title="www.ic3.gov" />
        </p>
      </li>
      <li>
        Federal Trade Commission (FTC)
        <p style={{ marginLeft: 15 }}>
          Identify Theft Hotline: <i>1 (877) ID-THEFT</i> <br />
          <ExternalLink href="https://www.ftc.gov" title="www.ftc.gov" />
        </p>
      </li>
      <li>
        Social Security Administration's Fraud Hotline
        <p style={{ marginLeft: 15 }}>
          <i>1 (800) 269-0271</i> <br />
          <ExternalLink href="https://www.ssa.gov/oig" title="www.ssa.gov" />
        </p>
      </li>
      <li>
        Credit Bureau Fraud Hotlines
        <p style={{ marginLeft: 15 }}>
          Equifax: <i>1 (800) 525-6285</i> <br />
          Experian: <i>1 (888) 397-3742</i> <br />
          TransUnion: <i>1 (800) 680-7289</i>
        </p>
      </li>

      <h3 id="other">Other Fraud Protection Resources</h3>
      <li>
        Department of Justice Internet Fraud Information
        <p style={{ marginLeft: 15 }}>
          <ExternalLink
            href="http://www.justice.gov/criminal/fraud/internet/"
            title="www.justice.gov"
          />
        </p>
      </li>
      <li>
        SEC Internet Fraud Information
        <p style={{ marginLeft: 15 }}>
          <ExternalLink
            href="http://www.sec.gov/investor/pubs/cyberfraud.htm/"
            title="www.sec.gov"
          />
        </p>
      </li>
      <li>
        Anti-Phishing Working Group
        <p style={{ marginLeft: 15 }}>
          <ExternalLink
            href="http://www.antiphishing.org"
            title="www.antiphishing.org"
          />
        </p>
      </li>
      <br />
    </CopywriteLayout>
  );
}
