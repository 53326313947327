import * as React from 'react';
import Container from '@mui/material/Container';

import LiteAppBar from '../components/nav/LiteAppBar';
import LiteAppFooter from '../components/nav/LiteAppFooter';
import LoansFormPage from '../components/forms/LoansForm';

function FormsPage() {
    return (
        <Container disableGutters>
            <LiteAppBar />
            <LoansFormPage />
            <LiteAppFooter />
        </Container>
    );
}

export default FormsPage;
