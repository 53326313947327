import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import LinkCard from '../../cards/LinkCard';

import AgreementIcon from '../../../assets/icons/banking/agreement.png';
import AtmIcon from '../../../assets/icons/banking/atm.png';
import CalendarIcon from '../../../assets/icons/banking/calendar.png';
import CloudIcon from '../../../assets/icons/banking/cloud.png';
import ComputerIcon from '../../../assets/icons/banking/computer.png';
import MachineIcon from '../../../assets/icons/banking/machine.png';

import MenuImage from '../../../assets/stock_photos/atm.png';

const ImageItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    // ...theme.typography.body2,
    ...theme.typography.body1,
    // padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    boxShadow: 'none', // toggle for development
    borderRadius: 0,
}));

export default function MaxWidthDialog() {
    const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('lg');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleMaxWidthChange = (event) => {
        setMaxWidth(
            // @ts-expect-error autofill of arbitrary value is not handled.
            event.target.value
        );
    };

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };

    return (
        <Box sx={{ flexGrow: 1, padding: 3, minHeight: '450px' }}>
            <Grid container spacing={0} columns={12}>
                {/* CONTAINER 1 */}
                <Grid item>
                    <Box
                        sx={{
                            boxShadow:
                                '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
                        }}
                    >
                        <ImageItem
                            style={{
                                backgroundImage: `url(${MenuImage})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover', // contain or cover
                                backgroundPosition: 'center',
                                height: 375,
                                width: 275,
                            }}
                        >
                            <ImageItem
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    backgroundColor: 'rgba(125, 125, 125, 0.5)',
                                    padding: '20px',
                                }}
                            >
                                <Typography
                                    marginTop="10px"
                                    variant="h4"
                                    color="white"
                                >
                                    <i>ATM fees waived globally</i>
                                </Typography>
                                <Typography
                                    marginTop="3%"
                                    color="white"
                                    fontSize={14}
                                    padding={1}
                                >
                                    Access ATMs worldwide within the CO-OP
                                    network
                                </Typography>
                                <Button
                                    href="/services#atm"
                                    variant="contained"
                                    sx={{
                                        marginTop: '25%',
                                        marginLeft: '3%',
                                        minWidth: '0',
                                        backgroundColor: '#056E8B',
                                        '&:hover': {
                                            backgroundColor: '#07a8d4',
                                        },
                                    }}
                                >
                                    Learn More
                                </Button>
                            </ImageItem>
                        </ImageItem>
                    </Box>
                </Grid>
                {/* CONTAINER 2 - width (xs) dependent on container 1 */}
                <Grid item xs>
                    <Box
                        sx={{
                            width: '100%',
                            height: 375,
                            paddingX: 4.5,
                            paddingY: 0,
                        }}
                    >
                        <Grid
                            container
                            spacing={{ xs: 4, md: 4 }}
                            columns={{ xs: 12 }}
                        >
                            <Grid item xs={6}>
                                <LinkCard
                                    title="Online Banking"
                                    label="Intuitively manage finances anytime, anywhere"
                                    icon={ComputerIcon}
                                    href="/services"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <LinkCard
                                    title="Loan Management"
                                    label="Effortless control, hassle-free loan management"
                                    icon={CalendarIcon}
                                    href="/services#loanmgmt"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <LinkCard
                                    title="Bill Pay"
                                    label="Simplify payments with convenient online bill-pay"
                                    icon={AgreementIcon}
                                    href="/services#billpay"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <LinkCard
                                    title="Remote Deposit"
                                    label="Deposit checks from anywhere, hassle-free"
                                    icon={CloudIcon}
                                    href="/services#rdc"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <LinkCard
                                    title="ATM"
                                    label="24/7 cash access, anytime, anywhere"
                                    icon={AtmIcon}
                                    href="/services#atm"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <LinkCard
                                    title="Card Machine"
                                    label="Instant card creation in house"
                                    icon={MachineIcon}
                                    href="/services#cm"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
